import { FunctionComponent, useMemo } from 'react';
import { Button, ButtonGroup, Modal, Text } from '@nike/eds';

import { IActionConfirmModalProps } from '../../redux/types/transferRecordsTypes';
import {
  API_TRANSFER_STATUS_CANCEL,
  API_TRANSFER_STATUS_CONFIRM,
  API_TRANSFER_STATUS_FACTORY,
  API_TRANSFER_STATUS_SOURCING,
  API_TRANSFER_STATUS_DRAFT,
} from '../common/RolesConstants';

const ActionConfirmModal: FunctionComponent<IActionConfirmModalProps> = ({
  onConfirm,
  isOpen,
  onDismiss,
  actionToConfirm,
}) => {
  const texts = useMemo(() => {
    if (actionToConfirm) {
      switch (actionToConfirm.status) {
        case API_TRANSFER_STATUS_CONFIRM:
          if (actionToConfirm.ids.length > 1) {
            return {
              headerText: `Are you sure you want to Confirm ${actionToConfirm.ids.length} transfer(s)?`,
              message:
                'Confirming these transfer will change its status to “Confirmed”',
              confirmText: 'Yes, Confirm Transfer(s)',
            };
          }
          return {
            headerText: 'Are you sure you want to Confirm this transfer?',
            message:
              'Confirming this transfer will change its status to “Confirmed”',
            confirmText: 'Yes, Confirm Transfer',
          };

        case API_TRANSFER_STATUS_CANCEL:
          if (actionToConfirm.ids.length > 1) {
            return {
              headerText: `Are you sure you want to Cancel ${actionToConfirm.ids.length} transfer(s)?`,
              message:
                'Cancelling these transfers will change its status to “Cancelled”',
              confirmText: 'Yes, Cancel Transfer(s)',
            };
          }
          return {
            headerText: 'Are you sure you want to Cancel this transfer?',
            message:
              'Cancelling this transfers will change its status to “Cancelled”',
            confirmText: 'Yes, Cancel Transfer',
          };

        case API_TRANSFER_STATUS_FACTORY:
          return {
            headerText:
              'Are you sure you want to send this transfer to Factory?',
            message:
              'Sending the transfer to Factory will change its status to “Factory”',
            confirmText: 'Yes, Send to Factory',
          };

        case API_TRANSFER_STATUS_SOURCING:
          return {
            headerText:
              'Are you sure you want to send this transfer to Sourcing?',
            message:
              'Sending the transfer Sourcing will change its status to “Sourcing”',
            confirmText: 'Yes, Send to Sourcing',
          };
        case API_TRANSFER_STATUS_DRAFT:
          return {
            headerText: 'Are you sure you want to send this transfer to Draft?',
            message:
              'Sending the transfer Draft will change its status to “Draft”',
            confirmText: 'Yes, Send to Draft',
          };

        default:
          break;
      }
    }

    return {
      headerText: '',
      message: '',
      confirmText: '',
    };
  }, [actionToConfirm]);

  return (
    <Modal
      onDismiss={onDismiss}
      isOpen={isOpen}
      headerSlot={<span className="dynamic-header">{texts.headerText}</span>}
      footerSlot={
        <ButtonGroup>
          <Button
            onClick={onConfirm}
            size="small"
            className="dynamic-confirm-button"
          >
            {texts.confirmText}
          </Button>
          <Button
            onClick={onDismiss}
            size="small"
            variant="secondary"
            className="dynamic-cancel-button"
          >
            Cancel
          </Button>
        </ButtonGroup>
      }
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Text font="body-1" as="p" className="dyanmic-message-text">
          {texts.message}
        </Text>
      </div>
    </Modal>
  );
};

export default ActionConfirmModal;
