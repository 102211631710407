export function pullFirstCancelDate(stateLog: any[]) {
  const filteredLogs = stateLog.filter(log =>
    Object.values(log).some(
      value => typeof value === 'string' && value.includes('Cancelled')
    )
  );

  if (filteredLogs.length === 0) {
    return null;
  }

  const latestLog = filteredLogs.reduce((latest, current) =>
    new Date(current.modifiedTimestamp) > new Date(latest.modifiedTimestamp)
      ? current
      : latest
  );

  return latestLog.modifiedTimestamp;
}

export function pullFirstConfirmDate(stateLog: any[]) {
  const filteredLogs = stateLog.filter(log =>
    Object.values(log).some(
      value => typeof value === 'string' && value.includes('Confirmed')
    )
  );

  if (filteredLogs.length === 0) {
    return null;
  }

  const latestLog = filteredLogs.reduce((latest, current) =>
    new Date(current.modifiedTimestamp) > new Date(latest.modifiedTimestamp)
      ? current
      : latest
  );

  return latestLog.modifiedTimestamp;
}
