import moment from 'moment';

export interface ITransferData {
  transferGeos: string[];
  transferType: {
    value: string | undefined | null;
    label: string | undefined | null;
  } | null;
  transferNotes: string;
  dueDate: Date;
  season: {
    value: string | undefined | null;
    label: string | undefined | null;
  } | null;
  productCode: {
    value: string | undefined | null;
    label: string | undefined | null;
  } | null;
  styleNumber: {
    value: string | undefined | null;
    label: string | undefined | null;
  } | null;
  styleName: string;
  productColorwayCode:
    | { value: string; label: string }[]
    | { value: string; label: string }
    | null;
  toFactory: any;
  fromFactory: any;
  toFactoryGroup: string;
  fromFactoryGroup: string;
  engineeringTransferType: {
    value: string | undefined | null;
    label: string | undefined | null;
  } | null;
  transferReason: {
    value: string | undefined | null;
    label: string | undefined | null;
  } | null;
  outsole: string | undefined | null;
  productPlanningGroup: {
    value: string | undefined | null;
    label: string | undefined | null;
  } | null;
  pccCodes: string | undefined;
  tdCode: string | undefined;
}

export const transferInitialState: ITransferData = {
  transferGeos: ['ALL'],
  transferType: null,
  transferNotes: '',
  dueDate: moment(new Date()).add(7, 'days').toDate(),
  season: null,
  productCode: null,
  styleNumber: null,
  styleName: '',
  productColorwayCode: [],
  toFactory: null,
  fromFactory: null,
  toFactoryGroup: '',
  fromFactoryGroup: '',
  engineeringTransferType: null,
  transferReason: null,
  outsole: '',
  productPlanningGroup: null,
  pccCodes: '',
  tdCode: '',
};

export interface ITransferDataError {
  transferType: boolean;
  dueDate: boolean;
  season: boolean;
  productCode: boolean;
  styleNumber: boolean;
  productColorwayCode: boolean;
  toFactory: boolean;
  fromFactory: boolean;
  toFactoryGroup: boolean;
  fromFactoryGroup: boolean;
  outsole: boolean;
  productPlanningGroup: boolean;
  pccCodes: boolean;
}

export interface ITransferDataErrorMessage {
  transferType: string;
  dueDate: string;
  season: string;
  productCode: string;
  styleNumber: string;
  productColorwayCode: string;
  toFactory: string;
  fromFactory: string;
  toFactoryGroup: string;
  fromFactoryGroup: string;
  outsole: string;
  productPlanningGroup: string;
  pccCodes: string;
}

export const TRANSFER_TYPE_TOOLING = 'Tooling';
export const TRANSFER_TYPE_TECH_PACK = 'Tech Pack';
export const TRANSFER_TYPE_OUTSOLE_COMPLETE = 'Outsole Complete';
export const TRANSFER_TYPE_EXISTING = 'Existing Dual/Multi-source';
export const TRANSFER_TYPE_MODEL_COMPLETE = 'Model Complete';
export const TRANSFER_TYPE_NEW = 'New Dual/Multi-source';
export const TRANSFER_TYPE_COMPLETE = 'Complete';
