import { Select } from '@nike/eds';
import { FC, useEffect } from 'react';
import { Props as ReactSelectProps } from 'react-select';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { ITransferData } from '../NewTranferInterface';
import { updateInputTouched } from '../../../redux/actions/inputActions';
import { fetchTransferReason } from '../../../redux/actions/newTransferActions';

interface FieldProps {
  transferData: any | ITransferData;
  setTransferData: (params: any) => any;
}

const TransferReason: FC<FieldProps> = ({ transferData, setTransferData }) => {
  const [transferReasons] = useAppSelector(state => [
    state.newTransfer.transferReasons,
  ]);

  const dispatch = useAppDispatch();

  const handleSelectInputChange: ReactSelectProps['onChange'] = (
    value: any
  ) => {
    setTransferData({ ...transferData, transferReason: value });

    dispatch<any>(updateInputTouched(true));
  };

  useEffect(() => {
    dispatch<any>(fetchTransferReason());
  }, []);

  return (
    <Select
      isClearable
      options={transferReasons}
      id="reason-trf-type-select"
      label="Transfer Reason"
      name="transferReason"
      onChange={handleSelectInputChange}
      value={transferData.transferReason}
      isDisabled={!transferData?.transferType?.value}
    />
  );
};

export default TransferReason;
