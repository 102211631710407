/* eslint-disable no-underscore-dangle */
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import apiConfig from '../../config/apiConfig';
import {
  ResponseCode,
  UpdateTransferStatusDispatchType,
  UPDATE_TRANSFER_STATUS_FAILURE,
  UPDATE_TRANSFER_STATUS_START,
  UPDATE_TRANSFER_STATUS_SUCCESS,
  UPDATE_TRANSFER_STATUS_RESET,
  IUpdateTransferStatusData,
} from '../types/updateTransferStatusTypes';
import apiInstance from '../axios/index';
import { POST_TRANSFER_DATA_DASHBOARD_SUCCESS } from '../types/newTransferTypes';
import {
  pullFirstCancelDate,
  pullFirstConfirmDate,
} from '../../utils/stateLogDate';

export const updateTransferRecordStatus =
  (status: string, data: IUpdateTransferStatusData) =>
  async (dispatch: Dispatch<UpdateTransferStatusDispatchType>) => {
    dispatch({ type: UPDATE_TRANSFER_STATUS_START });
    try {
      const updateTransferStatusUrl =
        apiConfig.baseURL +
        apiConfig.putUpdateTransferStatus.url.replace('{action}', status);

      const response = await apiInstance.patch(
        `${updateTransferStatusUrl}`,
        data
      );

      if (response && response.status === ResponseCode.OKAY) {
        dispatch({ type: UPDATE_TRANSFER_STATUS_SUCCESS });

        let successMessage = '';

        switch (status) {
          case 'cancel':
            successMessage = 'Transfer Cancelled successfully';
            break;

          case 'confirm':
            successMessage = 'Transfer Confirmed successfully';
            break;

          case 'factory':
            successMessage = 'Transfer sent to Factory successfully';
            break;

          case 'sourcing':
            successMessage = 'Transfer sent to Sourcing successfully';
            break;

          default:
            break;
        }

        toast.success(successMessage);
      } else {
        dispatch({ type: UPDATE_TRANSFER_STATUS_FAILURE, payload: { status } });
        toast.error('failed to update transfer');
      }

      for (let i = 0; i < response.data.response.itemsList.length; i += 1) {
        dispatch({
          type: POST_TRANSFER_DATA_DASHBOARD_SUCCESS,
          payload: {
            _id: response.data.response.itemsList[i]._id,
            ...response.data.response.itemsList[i].source,
            cancelledDate: pullFirstCancelDate(
              response.data.response.itemsList[i].source.stateLog
            ),
            confirmedDate: pullFirstConfirmDate(
              response.data.response.itemsList[i].source.stateLog
            ),
          },
        });
      }
    } catch (err: any) {
      const errData = err.response?.data || {};
      dispatch({
        type: UPDATE_TRANSFER_STATUS_FAILURE,
        payload: { status, ...errData },
      });
    }
  };

export const updateTransferStatusReset = () => ({
  type: UPDATE_TRANSFER_STATUS_RESET,
});
