import { useMemo } from 'react';

const ViewCustomTooltip = (props: any) => {
  const data = useMemo(
    () => props?.api.getDisplayedRowAtIndex(props?.rowIndex)?.data,
    []
  );

  const displayComment = () => {
    const commentDate: any[] = [];
    if (data.ToFactoryCommentCreateTimestamp) {
      commentDate.push({
        ToFactoryComments: new Date(data.ToFactoryCommentCreateTimestamp),
      });
    }
    if (data.FromFactoryCommentCreateTimestamp) {
      commentDate.push({
        FromFactoryComments: new Date(data.FromFactoryCommentCreateTimestamp),
      });
    }
    if (data.WHQCommentCreateTimestamp) {
      commentDate.push({
        WHQComments: new Date(data.WHQCommentCreateTimestamp),
      });
    }
    if (data.PMOCommentCreateTimestamp) {
      commentDate.push({
        PMOComments: new Date(data.PMOCommentCreateTimestamp),
      });
    }

    const sortable = commentDate.sort((a, b) => (a > b ? 1 : -1));

    if (sortable.length > 0) {
      const first = Object.keys(sortable[0])[0];
      if (first === 'ToFactoryComments') {
        return `Comment: ${data.ToFactoryComments}`;
      }
      if (first === 'FromFactoryComments') {
        return `Comment: ${data.FromFactoryComments}`;
      }
      if (first === 'WHQComments') {
        return `Comment: ${data.WHQComments}`;
      }
      if (first === 'PMOComments') {
        return `Comment: ${data.PMOComments}`;
      }
    }

    return 'No Comments Found';
  };

  return (
    <div className="custom-tooltip">
      <div>{displayComment()}</div>
    </div>
  );
};

export default ViewCustomTooltip;
