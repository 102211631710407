/* eslint-disable no-underscore-dangle */
import { FC, useState, useEffect } from 'react';
import { Spinner, Icon } from '@nike/eds';
import { useLocation } from 'react-router-dom';
import { ITransferData } from './NewTranferInterface';
import {
  fetchUserRole,
  fetchUserIdentifier,
} from '../../redux/actions/userActionActions';
import { duplicateTransferRecordItem } from '../../redux/actions/transferRecordItemActions';
import {
  fetchFromFactoryList,
  fetchOutsoleCodes,
  fetchPccCodes,
  fetchProductColorwayCodeList,
  fetchProductPlanningGroup,
  fetchSeasonList,
  fetchProductId,
  fetchStyleNumberList,
  fetchProductCodeList,
  fetchToFactoryList,
  resetNewTransferData,
  fetchStyleNumberListBySeason,
  fetchProductPlanningGroupList,
  fetchStyleNameWithStyleNumber,
  fetchSourcingSeasonList,
  fetchSourcingStyleNumberListBySeason,
  fetchSourcingProductCodeList,
  fetchSourcingProductColorwayCodeList,
  fetchAllFactoryList,
  clearProductInfo,
} from '../../redux/actions/newTransferActions';
import { roles } from '../common/RolesConstants';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { hideErrorActionCreator } from '../../redux/actions/errorActions';
import Checkboxes from './FormFields/Checkboxes';
import DueDate from './FormFields/DueDate';
import ColorwayCode from './FormFields/ColorwayCode';
import TransferType from './FormFields/TransferType';
import TdCode from './FormFields/TdCode';
import TransferNotes from './FormFields/TransferNotes';
import StyleNumber from './FormFields/StyleNumber';
import Season from './FormFields/Season';
import ProductCode from './FormFields/ProductCode';
import ProductPlanningGroup from './FormFields/ProductPlanningGroup';
import TransferReason from './FormFields/TransferReason';
import EngineeringType from './FormFields/EngineeringType';
import Outsole from './FormFields/Outsole';
import ProductCreationCenter from './FormFields/ProductCreationCenter';
import ToFactory from './FormFields/ToFactory';
import FromFactory from './FormFields/FromFactory';
import FromFactoryGroup from './FormFields/FromFactoryGroup';
import ToFactoryGroup from './FormFields/ToFactoryGroup';
import StyleName from './FormFields/StyleName';

interface FormProps {
  transferData: any | ITransferData;
  setTransferData: (params: any) => any;
  enableAutoPopulateFields?: boolean;
}

const Form: FC<FormProps> = ({
  transferData,
  setTransferData,
  enableAutoPopulateFields = true,
}) => {
  const [isOpenAdditionalInfo, setIsOpenAdditionalInfo] =
    useState<boolean>(true);
  const [factoryIsToOverwritten, setFactoryIsToOverwritten] =
    useState<boolean>(true);
  const [factoryIsFromOverwritten, setFactoryIsFromOverwritten] =
    useState<boolean>(true);

  const dispatch = useAppDispatch();
  const location = useLocation();

  const [
    userRole,
    loading,
    pccLoading,
    productOfferingIdentifier,
    error,
    isEditMode,
    productInfo,
  ] = useAppSelector(state => [
    state.userRole.role,
    state.newTransfer.loading,
    state.newTransfer.pccLoading,
    state.newTransfer.productInfo?._source.productOfferingIdentifier,
    state.error,
    state.input.isEditMode,
    state.newTransfer.productInfo,
  ]);

  useEffect(() => {
    dispatch<any>(fetchUserRole());
    dispatch<any>(fetchUserIdentifier());

    if (enableAutoPopulateFields) {
      dispatch<any>(fetchAllFactoryList());
    }

    // fetch data for duplicate action.
    if (location.state) {
      dispatch<any>(duplicateTransferRecordItem(location.state));
    }
  }, []);

  useEffect(() => {
    if (enableAutoPopulateFields) {
      if (userRole === roles.factory) {
        dispatch<any>(fetchSourcingSeasonList());
      } else if (userRole) {
        dispatch<any>(fetchSeasonList());
        dispatch<any>(fetchStyleNumberList());
      }
      dispatch<any>(fetchProductPlanningGroupList());
    }

    return () => {
      dispatch<any>(resetNewTransferData());
    };
  }, [userRole]);

  useEffect(() => {
    if (
      productOfferingIdentifier !== undefined &&
      productOfferingIdentifier !== null &&
      enableAutoPopulateFields
    ) {
      dispatch<any>(fetchPccCodes(productOfferingIdentifier));
    }
  }, [productOfferingIdentifier]);

  const dispatchForSeasonStyleColorway = () => {
    if (
      transferData.season?.value &&
      transferData.styleNumber?.value &&
      transferData.productColorwayCode
    ) {
      if (transferData.productColorwayCode?.value && enableAutoPopulateFields) {
        dispatch<any>(
          fetchProductId(
            transferData.season.value,
            transferData.styleNumber.value,
            transferData.productColorwayCode.value
          )
        );
        dispatch<any>(
          fetchToFactoryList(
            transferData.styleNumber.value,
            transferData.season.value,
            transferData.productColorwayCode.value
          )
        );
        dispatch<any>(
          fetchFromFactoryList(
            transferData.styleNumber.value,
            transferData.season.value,
            transferData.productColorwayCode.value
          )
        );
      }
    }
  };

  const dispatchForSeasonStyle = () => {
    if (
      transferData.season?.value &&
      transferData.styleNumber?.value &&
      enableAutoPopulateFields
    ) {
      if (userRole === roles.factory) {
        dispatch<any>(
          fetchSourcingProductColorwayCodeList(
            transferData.season.value,
            transferData.styleNumber.value
          )
        );
      } else {
        dispatch<any>(
          fetchProductColorwayCodeList(
            transferData.season.value,
            transferData.styleNumber.value
          )
        );
      }
    }
  };

  const dispatchForStyle = () => {
    if (transferData.styleNumber?.value && enableAutoPopulateFields) {
      dispatch<any>(fetchOutsoleCodes(transferData.styleNumber.value));
      dispatch<any>(fetchProductPlanningGroup(transferData.styleNumber.value));
      dispatch<any>(
        fetchStyleNameWithStyleNumber(transferData.styleNumber.value)
      );
    }
  };

  const dispatchForSeason = () => {
    if (transferData.season?.value && enableAutoPopulateFields) {
      if (userRole === roles.factory) {
        dispatch<any>(fetchSourcingProductCodeList(transferData.season.value)); // fetch product codes
        dispatch<any>(
          fetchSourcingStyleNumberListBySeason(transferData.season.value)
        );
      } else {
        dispatch<any>(fetchProductCodeList(transferData.season.value)); // fetch product codes
        dispatch<any>(fetchStyleNumberListBySeason(transferData.season.value));
      }
    }
  };

  useEffect(() => {
    dispatchForSeasonStyleColorway();

    if (transferData.productCode?.value) {
      const styleNumberSplit = transferData.productCode.value.split('-')[0];
      const productColorwayCodeSplit =
        transferData.productCode.value.split('-')[1];
      if (styleNumberSplit) {
        setTransferData({
          ...transferData,
          styleNumber: {
            value: styleNumberSplit,
            label: styleNumberSplit,
          },
          productColorwayCode: {
            value: productColorwayCodeSplit,
            label: productColorwayCodeSplit,
          },
        });

        // reset error
        dispatch<any>(
          hideErrorActionCreator([
            'productPlanningGroup',
            'outsole',
            'styleNumber',
            'productColorwayCode',
          ])
        );
      }
    } else if (
      transferData.styleNumber?.value ||
      (transferData.productColorwayCode &&
        transferData.productColorwayCode[0]?.value)
    ) {
      const updatedTransferData = {
        ...transferData,
      };

      if (updatedTransferData.styleNumber?.value) {
        updatedTransferData.styleNumber = null;
      }
      if (
        updatedTransferData.productColorwayCode &&
        updatedTransferData.productColorwayCode[0]?.value
      ) {
        updatedTransferData.productColorwayCode = [];
      }
    }
  }, [transferData.productCode]);

  useEffect(() => {
    if (
      !(
        transferData.season?.value &&
        transferData.styleNumber?.value &&
        transferData.productColorwayCode
      ) &&
      productInfo
    ) {
      dispatch<any>(clearProductInfo());
    }
  }, [
    transferData.season,
    transferData.styleNumber,
    transferData.productColorwayCode,
    productInfo,
  ]);

  useEffect(() => {
    dispatchForSeasonStyleColorway();

    if (transferData.productColorwayCode?.value === '' && !isEditMode) {
      setTransferData({
        ...transferData,
        productCode: null,
        tdCode: '',
      });
    }

    let productCodeString = '';

    // if colorway is a string
    if (
      transferData.styleNumber?.value &&
      transferData.productColorwayCode &&
      transferData.productColorwayCode?.value
    ) {
      if (!error.productColorwayCodeError) {
        productCodeString = `${transferData?.styleNumber.value}-${transferData?.productColorwayCode.value}`;
      } else {
        productCodeString = '';
      }
    }

    // if colorway is an array
    if (
      transferData.styleNumber?.value &&
      transferData.productColorwayCode &&
      transferData.productColorwayCode[0]?.value
    ) {
      if (!error.productColorwayCodeError) {
        productCodeString = `${transferData?.styleNumber.value}-${transferData?.productColorwayCode[0].value}`;
      } else {
        productCodeString = '';
      }
    }

    if (
      productCodeString !== transferData.productCode?.value &&
      productCodeString !== ''
    ) {
      setTransferData({
        ...transferData,
        productCode: {
          value: productCodeString,
          label: productCodeString,
        },
      });
    }

    if (!error.productColorwayCodeError && transferData.productCode?.value) {
      dispatch<any>(
        hideErrorActionCreator([
          'productPlanningGroup',
          'outsole',
          'styleNumber',
          'productColorwayCode',
        ])
      );
    }
  }, [transferData.productColorwayCode]);

  useEffect(() => {
    dispatchForSeasonStyleColorway();
    dispatchForSeasonStyle();
    dispatchForSeason();

    // reset error
    dispatch<any>(
      hideErrorActionCreator(['productPlanningGroup', 'outsole', 'styleNumber'])
    );
  }, [transferData.season]);

  useEffect(() => {
    dispatchForSeasonStyleColorway();
    dispatchForSeasonStyle();
    dispatchForStyle();

    if (!transferData.styleNumber?.value) {
      setTransferData({
        ...transferData,
        productCode: transferData.productCode,
        productColorwayCode: [],
      });

      // reset error
      dispatch<any>(
        hideErrorActionCreator([
          'productPlanningGroup',
          'outsole',
          'productColorwayCode',
        ])
      );
    }

    if (transferData.productCode?.value) {
      const styleNumberSplit = transferData.productCode.value.split('-')[0];
      if (transferData.styleNumber?.value !== styleNumberSplit) {
        setTransferData({
          ...transferData,
          productCode: null,
          productColorwayCode: [],
        });
      }
    }
  }, [transferData.styleNumber]);

  return (
    <div>
      {/* Transfer Information */}
      <div>
        <div className="new-info-form-container">
          <div className="input-groups">
            <div className="input-group">
              <div className="form-input-container">
                <TransferType
                  transferData={transferData}
                  setTransferData={setTransferData}
                />
              </div>
            </div>
            <div className="input-group">
              <DueDate
                transferData={transferData}
                setTransferData={setTransferData}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Product Information */}
      <div>
        <div className="product-info-form-container">
          <div>
            <div className="input-groups">
              <div className="input-group">
                <div className="form-input-container">
                  <Season
                    transferData={transferData}
                    setTransferData={setTransferData}
                  />
                </div>
                <div className="form-input-container">
                  <ProductCode
                    transferData={transferData}
                    setTransferData={setTransferData}
                  />
                </div>
                <div className="form-input-container">
                  <StyleNumber
                    transferData={transferData}
                    setTransferData={setTransferData}
                  />
                </div>
                <div className="form-input-container">
                  <ColorwayCode
                    transferData={transferData}
                    setTransferData={setTransferData}
                  />
                </div>
              </div>
              <div className="input-group">
                <div className="form-input-container">
                  <StyleName
                    transferData={transferData}
                    setTransferData={setTransferData}
                    enableAutoPopulateFields={enableAutoPopulateFields}
                  />
                </div>
                <div className="form-input-container">
                  <ProductPlanningGroup
                    transferData={transferData}
                    setTransferData={setTransferData}
                    enableAutoPopulateFields={enableAutoPopulateFields}
                  />
                </div>
                <div className="form-input-container field-with-loading">
                  {loading ? <Spinner id="spinner" /> : null}
                  <Outsole
                    transferData={transferData}
                    setTransferData={setTransferData}
                    enableAutoPopulateFields={enableAutoPopulateFields}
                  />
                </div>

                <div className="form-input-container field-with-loading">
                  {pccLoading ? <Spinner id="spinner" /> : null}
                  <ProductCreationCenter
                    transferData={transferData}
                    setTransferData={setTransferData}
                    enableAutoPopulateFields={enableAutoPopulateFields}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Factory Information */}
      <div>
        <div className="factory-info-form-container">
          <div>
            <div className="input-groups">
              <div className="input-group">
                <div className="form-input-container">
                  <FromFactory
                    transferData={transferData}
                    setTransferData={setTransferData}
                    setFactoryIsFromOverwritten={setFactoryIsFromOverwritten}
                    setFactoryIsToOverwritten={setFactoryIsToOverwritten}
                    enableAutoPopulateFields={enableAutoPopulateFields}
                  />
                </div>
                <div className="form-input-container">
                  <ToFactory
                    transferData={transferData}
                    setTransferData={setTransferData}
                    setFactoryIsFromOverwritten={setFactoryIsFromOverwritten}
                    setFactoryIsToOverwritten={setFactoryIsToOverwritten}
                    enableAutoPopulateFields={enableAutoPopulateFields}
                  />
                </div>
              </div>
              <div className="input-group">
                <div className="form-input-container">
                  <FromFactoryGroup
                    transferData={transferData}
                    setTransferData={setTransferData}
                    factoryIsFromOverwritten={factoryIsFromOverwritten}
                  />
                </div>
                <div className="form-input-container">
                  <ToFactoryGroup
                    transferData={transferData}
                    setTransferData={setTransferData}
                    factoryIsToOverwritten={factoryIsToOverwritten}
                  />
                </div>
              </div>
            </div>
            <div className="transfer-note-container">
              <div className="input-group">
                <div className="form-input-container">
                  <TransferNotes
                    transferData={transferData}
                    setTransferData={setTransferData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Additional Transfer Information */}
      <div>
        <div className="additional-info-form-container">
          <hr className="top-hr" />
          <div>
            <div className="form-title">
              <p>Additional Transfer Information</p>
              {isOpenAdditionalInfo ? (
                <Icon
                  name="CaretDown"
                  className="collapse-icon"
                  onClick={() => setIsOpenAdditionalInfo(false)}
                />
              ) : (
                <Icon
                  name="CaretUp"
                  className="collapse-icon"
                  onClick={() => setIsOpenAdditionalInfo(true)}
                />
              )}
            </div>
          </div>
          {isOpenAdditionalInfo && (
            <div>
              <div className="input-groups">
                <div className="input-group">
                  <div className="form-input-container">
                    <EngineeringType
                      transferData={transferData}
                      setTransferData={setTransferData}
                    />
                  </div>
                </div>
                <div className="input-group">
                  <div className="form-input-container">
                    <Checkboxes
                      transferData={transferData}
                      setTransferData={setTransferData}
                    />
                  </div>
                </div>
              </div>
              <div className="input-groups">
                <div className="input-group">
                  <div className="form-input-container">
                    <TransferReason
                      transferData={transferData}
                      setTransferData={setTransferData}
                    />
                  </div>
                </div>
                <div className="input-group">
                  <div className="form-input-container">
                    <TdCode
                      transferData={transferData}
                      setTransferData={setTransferData}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Form.defaultProps = {
  enableAutoPopulateFields: true,
};

export default Form;
