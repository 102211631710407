/* eslint-disable import/no-cycle */
import {
  USER_FACTORY_LOADING_END,
  USER_FACTORY_LOADING_START,
  USER_FACTORY_LOADING_SUCCESS,
  UserFactoryDispatchType,
} from '../types/userActionTypes';

interface IUserFactoryAssignmentDefaultState {
  loading: boolean;
  factories: Array<any> | any;
}

const defaultState: IUserFactoryAssignmentDefaultState = {
  loading: false,
  factories: [],
};

const userFactoryAssignmentReducer = (
  state: IUserFactoryAssignmentDefaultState = defaultState,
  action: UserFactoryDispatchType
): IUserFactoryAssignmentDefaultState => {
  switch (action.type) {
    case USER_FACTORY_LOADING_START:
      return { ...state, loading: true };
    case USER_FACTORY_LOADING_END:
      return { ...state, loading: false };
    case USER_FACTORY_LOADING_SUCCESS:
      return {
        ...state,
        factories: action.payload || {},
      };
    default:
      return state;
  }
};

export default userFactoryAssignmentReducer;
