/* eslint-disable prettier/prettier */
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
const ON_DEMAND_API_BASE_URL =
  process.env.REACT_APP_ON_DEAMAND_API_BASE_URL || '';

const apiConfig = {
  baseURL: API_BASE_URL,
  onDemandApiBaseURL: ON_DEMAND_API_BASE_URL,
  getTransfers: { method: 'GET', url: 'v1/transfers' },
  getTransferItem: { method: 'GET', url: 'v1/transfers/{id}' },
  getSttToken: { method: 'GET', url: 'v1/token' },
  getPpgList: { method: 'GET', url: 'v1/ppgs' },
  getProductInformation: { method: 'GET', url: 'v1/products/{type}' },
  getSourcingProductInformation: { method: 'GET', url: 'v1/sourcingproducts/{type}' },
  getFactory: { method: 'GET', url: 'v1/sources/{type}' },
  getAllFactory: { method: 'GET', url: 'v1/factories/{type}' },
  getPpgInformation: { method: 'GET', url: 'v1/ppg' },
  getPccCodes: { method: 'GET', url: 'get-pcc-codes' },
  getOutsoleCodes: { method: 'GET', url: 'get-out-sole-codes' },
  updateTransferData: { method: 'PUT', url: 'v1/transfers/{id}' },
  createTransferData: { method: 'POST', url: 'v1/transfers' },
  putUpdateTransferStatus: { method: 'PATCH', url: 'v1/transfers/bulk/{action}'},
  getFactoryAssignments: { method: 'GET', url: 'v1/users' },
  patchTransferComments: { method: 'PATCH', url: 'v1/transfers/{id}/comments' },
  patchTransferFlag: { method: 'PATCH', url: 'v1/transfers/{id}/flag/{value}' },
  getSourcingAssignments: { method: 'GET', url: 'v1/sourcing' },
  downloadTransferAttachments: { method: 'GET', url: 'v1/transfers/attachments?fileName=' },
  uploadTransferAttachments: { method: 'PUT', url: 'v1/transfers/{id}/attachments' },
  deleteTransferAttachments: { method: 'POST', url: 'v1/transfers/{id}/deleteAttachments/{fileName}' },
  deleteTransferRecord: { method: 'DELETE', url: 'v1/transfers/{id}' },
  uploadTransferAttachmentGenerateS3Url: { method: 'get', url: 'v1/transfers/{id}/attachments/S3Path' },
  uploadTransferAttachmentUpdateRecord: { method: 'PUT', url: 'v1/transfers/{id}/attachments/TransferUpdate' },
  getUserGridPreferences: { method: 'GET', url: 'v1/user-grid-preferences' },
  updateUserGridPreferences: { method: 'PUT', url: 'v1/user-grid-preferences' },
  getEngineeringTransferTypes: { method: 'GET', url: 'v1/engineering-transfer-types' },
  getTransferTypes: { method: 'GET', url: 'v1/transfer-types' },
  getTransferReasons: { method: 'GET', url: 'v1/transfer-reason' },
};
export default apiConfig;
