import { FC } from 'react';
import { Box, Card, Icon, Button } from '@nike/eds';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { CaretLeft } from '@nike/nike-design-system-icons';
import { useNavigate } from 'react-router-dom';

import './Help.scss';

const Help: FC = () => {
  const navigate = useNavigate();

  const clickCancel = () => {
    navigate('/');
  };

  return (
    <div className="view-transfer-container">
      <Button
        className="eds-color--grey-7"
        size="medium"
        variant="ghost"
        id="back-to-dashboard-transfer-button"
        onClick={clickCancel}
        beforeSlot={<CaretLeft />}
      >
        Back to Dashboard
      </Button>
      <Box className="eds-grid eds-grid--m-cols-2 eds-spacing--p-24">
        {/* Required Fields */}
        <Card>
          <Icon
            backgroundColor="var(--eds-color-black)"
            color="var(--eds-color-white)"
            backgroundShape="circle"
            size="l"
            name="AR"
            enableFocus
          />
          <p className="eds-spacing--py-20">Required Fields</p>
          <Tabs>
            <TabList>
              <Tab>Draft</Tab>
              <Tab>Factory</Tab>
              <Tab>Sourcing</Tab>
              <Tab>Confirmed</Tab>
              <Tab>Cancelled</Tab>
            </TabList>
            {/* Draft */}
            <TabPanel>
              <div className="eds-grid eds-grid--m-cols-2 eds-grid--items-align-center">
                <h6>Existing Dual/Multi-source</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Style Number</ol>
                </ul>
                <h6>Complete</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Style Number</ol>
                </ul>
                <h6>Model Complete</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Style Number</ol>
                </ul>
                <h6>New Dual/Multi-source</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Style Number</ol>
                </ul>
                <h6>Outsole Complete</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Style Number</ol>
                </ul>
                <h6>Tech Pack</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Style Number</ol>
                </ul>
                <h6>Tooling</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Style Number</ol>
                </ul>
              </div>
            </TabPanel>
            {/* Factory */}
            <TabPanel>
              <div className="eds-grid eds-grid--m-cols-2 eds-grid--items-align-center">
                <h6>Existing Dual/Multi-source</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Style Number</ol>
                  <ol>Product Planning Group</ol>
                  <ol>To Factory Code</ol>
                </ul>
                <h6>Complete</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Style Number</ol>
                  <ol>Product Planning Group</ol>
                  <ol>To Factory Code</ol>
                </ul>
                <h6>Model Complete</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Style Number</ol>
                  <ol>Product Planning Group</ol>
                  <ol>To Factory Code</ol>
                </ul>
                <h6>New Dual/Multi-source</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Style Number</ol>
                  <ol>Product Planning Group</ol>
                  <ol>To Factory Code</ol>
                </ul>
                <h6>Outsole Complete</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Style Number</ol>
                  <ol>Product Planning Group</ol>
                  <ol>Outsole Complete</ol>
                </ul>
                <h6>Tech Pack</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Style Number</ol>
                  <ol>Product Planning Group</ol>
                  <ol>To Factory Code</ol>
                </ul>
                <h6>Tooling</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Style Number</ol>
                  <ol>Product Planning Group</ol>
                  <ol>Outsole Complete</ol>
                </ul>
              </div>
            </TabPanel>
            {/* Sourcing */}
            <TabPanel>
              <div className="eds-grid eds-grid--m-cols-2 eds-grid--items-align-center">
                <h6>Existing Dual/Multi-source</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Style Number</ol>
                  <ol>Product Planning Group</ol>
                  <ol>To Factory Code</ol>
                </ul>
                <h6>Complete</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Style Number</ol>
                  <ol>Product Planning Group</ol>
                  <ol>To Factory Code</ol>
                </ul>
                <h6>Model Complete</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Style Number</ol>
                  <ol>Product Planning Group</ol>
                  <ol>To Factory Code</ol>
                </ul>
                <h6>New Dual/Multi-source</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Style Number</ol>
                  <ol>Product Planning Group</ol>
                  <ol>To Factory Code</ol>
                </ul>
                <h6>Outsole Complete</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Style Number</ol>
                  <ol>Product Planning Group</ol>
                  <ol>Outsole Complete</ol>
                </ul>
                <h6>Tech Pack</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Style Number</ol>
                  <ol>Product Planning Group</ol>
                  <ol>To Factory Code</ol>
                </ul>
                <h6>Tooling</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Style Number</ol>
                  <ol>Product Planning Group</ol>
                  <ol>Outsole Complete</ol>
                </ul>
              </div>
            </TabPanel>
            {/* Confirmed */}
            <TabPanel>
              <div className="eds-grid eds-grid--m-cols-2 eds-grid--items-align-center">
                <h6>Existing Dual/Multi-source</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Product Creation Center Code</ol>
                  <ol>Product Planning Group</ol>
                  <ol>Outsole Code</ol>
                  <ol>To Factory Code</ol>
                  <ol>From Factory Code</ol>
                </ul>
                <h6>Complete</h6>
                <ul>
                  <ol>Product Creation Center Code</ol>
                  <ol>Product Planning Group</ol>
                  <ol>To Factory Code</ol>
                  <ol>From Factory Code</ol>
                </ul>
                <h6>Model Complete</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Product Creation Center Code</ol>
                  <ol>Product Planning Group</ol>
                  <ol>To Factory Code</ol>
                  <ol>From Factory Code</ol>
                </ul>
                <h6>New Dual/Multi-source</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Product Creation Center Code</ol>
                  <ol>Product Planning Group</ol>
                  <ol>Outsole Code</ol>
                  <ol>To Factory Code</ol>
                  <ol>From Factory Code</ol>
                </ul>
                <h6>Outsole Complete</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Product Planning Group</ol>
                  <ol>Outsole Code</ol>
                  <ol>To Factory Code</ol>
                  <ol>From Factory Code</ol>
                </ul>
                <h6>Tech Pack</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Product Creation Center Code</ol>
                  <ol>Product Planning Group</ol>
                  <ol>To Factory Code</ol>
                  <ol>From Factory Code</ol>
                </ul>
                <h6>Tooling</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Product Planning Group</ol>
                  <ol>Outsole Code</ol>
                  <ol>To Factory Code</ol>
                  <ol>From Factory Code</ol>
                </ul>
              </div>
            </TabPanel>
            {/* Cancelled */}
            <TabPanel>
              <div className="eds-grid eds-grid--m-cols-2 eds-grid--items-align-center">
                <h6>Existing Dual/Multi-source</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Style Number</ol>
                  <ol>Product Planning Group</ol>
                  <ol>To Factory Code</ol>
                </ul>
                <h6>Complete</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Style Number</ol>
                  <ol>Product Planning Group</ol>
                  <ol>To Factory Code</ol>
                </ul>
                <h6>Model Complete</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Style Number</ol>
                  <ol>Product Planning Group</ol>
                  <ol>To Factory Code</ol>
                </ul>
                <h6>New Dual/Multi-source</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Style Number</ol>
                  <ol>Product Planning Group</ol>
                  <ol>To Factory Code</ol>
                </ul>
                <h6>Outsole Complete</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Product Planning Group</ol>
                  <ol>Outsole Code</ol>
                  <ol>To Factory Code</ol>
                </ul>
                <h6>Tech Pack</h6>
                <ul>
                  <ol>Season</ol>
                  <ol>Style Number</ol>
                  <ol>Product Planning Group</ol>
                  <ol>To Factory Code</ol>
                </ul>
                <h6>Tooling</h6>
                <ol>
                  <li>Season</li>
                  <li>Product Planning Group</li>
                  <li>Outsole Code</li>
                  <li>To Factory Code</li>
                </ol>
              </div>
            </TabPanel>
          </Tabs>
        </Card>
        {/* Uniqueness Check */}
        <Card>
          <Icon
            backgroundColor="var(--eds-color-black)"
            color="var(--eds-color-white)"
            backgroundShape="circle"
            size="l"
            name="Tip"
            enableFocus
          />
          <p className="eds-spacing--py-20">Uniqueness Check</p>
          <div className="eds-grid eds-grid--m-cols-2 eds-grid--items-align-center">
            <h6>Existing Dual/Multi-source</h6>
            <ol>
              <li>Transfer Type</li>
              <li>Season</li>
              <li>Style Number</li>
              <li>Colorway Code</li>
              <li>Product Code</li>
              <li>Product Creation Cetner Code</li>
              <li>To Factory Code</li>
              <li>From Factory Code</li>
            </ol>
            <h6>Complete</h6>
            <ol>
              <li>Transfer Type</li>
              <li>Season</li>
              <li>Style Number</li>
              <li>Product Creation Cetner Code</li>
              <li>To Factory Code</li>
              <li>From Factory Code</li>
            </ol>
            <h6>Model Complete</h6>
            <ol>
              <li>Transfer Type</li>
              <li>Season</li>
              <li>Style Number</li>
              <li>Product Creation Cetner Code</li>
              <li>To Factory Code</li>
              <li>From Factory Code</li>
            </ol>
            <h6>New Dual/Multi-source</h6>
            <ol>
              <li>Transfer Type</li>
              <li>Season</li>
              <li>Style Number</li>
              <li>Colorway Code</li>
              <li>Product Code</li>
              <li>Product Creation Cetner Code</li>
              <li>To Factory Code</li>
              <li>From Factory Code</li>
            </ol>
            <h6>Outsole Complete</h6>
            <ol>
              <li>Transfer Type</li>
              <li>Season</li>
              <li>Outsole Code</li>
              <li>To Factory Code</li>
              <li>From Factory Code</li>
            </ol>
            <h6>Tech Pack</h6>
            <ol>
              <li>Transfer Type</li>
              <li>Season</li>
              <li>Style Number</li>
              <li>Product Creation Cetner Code</li>
              <li>To Factory Code</li>
              <li>From Factory Code</li>
            </ol>
            <h6>Tooling</h6>
            <ol>
              <li>Transfer Type</li>
              <li>Season</li>
              <li>Outsole Code</li>
              <li>To Factory Code</li>
              <li>From Factory Code</li>
            </ol>
          </div>
        </Card>
        {/* Workflow */}
        <Card>
          <Icon
            backgroundColor="var(--eds-color-black)"
            color="var(--eds-color-white)"
            backgroundShape="circle"
            size="l"
            name="Compare"
            enableFocus
          />
          <p className="eds-spacing--py-20">Workflow</p>
          <Tabs>
            <TabList>
              <Tab>Draft</Tab>
              <Tab>Factory</Tab>
              <Tab>Sourcing</Tab>
              <Tab>Confirmed</Tab>
              <Tab>Cancelled</Tab>
            </TabList>
            <TabPanel>
              <p className="eds-spacing--py-20">Valid Transfer Moves</p>
              <div className="eds-button-group">
                <Button
                  onClick={() => {}}
                  title="Factory"
                  id="factory-toggle"
                  className="view-state-buttons"
                >
                  Factory
                </Button>
                <Button
                  onClick={() => {}}
                  title="Sourcing"
                  id="sourcing-toggle"
                  className="view-state-buttons"
                >
                  Sourcing
                </Button>
                <Button
                  onClick={() => {}}
                  title="Confirmed"
                  id="confirmed-toggle"
                  className="view-state-buttons"
                >
                  Confirmed
                </Button>
              </div>
            </TabPanel>
            <TabPanel>
              <p className="eds-spacing--py-20">Valid Transfer Moves</p>
              <div className="eds-button-group">
                <Button
                  onClick={() => {}}
                  title="Factory"
                  id="draft-toggle"
                  className="view-state-buttons"
                >
                  Draft
                </Button>
                <Button
                  onClick={() => {}}
                  title="Sourcing"
                  id="sourcing-toggle"
                  className="view-state-buttons"
                >
                  Sourcing
                </Button>
              </div>
            </TabPanel>
            <TabPanel>
              <p className="eds-spacing--py-20">Valid Transfer Moves</p>
              <div className="eds-button-group">
                <Button
                  onClick={() => {}}
                  title="Factory"
                  id="factory-toggle"
                  className="view-state-buttons"
                >
                  Factory
                </Button>
                <Button
                  onClick={() => {}}
                  title="Sourcing"
                  id="confirmed-toggle"
                  className="view-state-buttons"
                >
                  Confirmed
                </Button>
                <Button
                  onClick={() => {}}
                  title="Confirmed"
                  id="cancelled-toggle"
                  className="view-state-buttons"
                >
                  Cancelled
                </Button>
              </div>
            </TabPanel>
            <TabPanel>
              <p className="eds-spacing--py-20">Valid Transfer Moves</p>
              <div className="eds-button-group">
                <Button
                  onClick={() => {}}
                  title="Factory"
                  id="cancelled-toggle"
                  className="view-state-buttons"
                >
                  Cancelled
                </Button>
                <Button
                  onClick={() => {}}
                  title="Sourcing"
                  id="sourcing-toggle"
                  className="view-state-buttons"
                >
                  Sourcing
                </Button>
              </div>
            </TabPanel>
            <TabPanel>
              <p className="eds-spacing--py-20">Valid Transfer Moves</p>
              <div className="eds-button-group">
                <Button
                  onClick={() => {}}
                  title="Factory"
                  id="factory-toggle"
                  className="view-state-buttons"
                >
                  Factory
                </Button>
                <Button
                  onClick={() => {}}
                  title="Sourcing"
                  id="confirmed-toggle"
                  className="view-state-buttons"
                >
                  Confirmed
                </Button>
              </div>
            </TabPanel>
          </Tabs>
        </Card>
        {/* Generated Fields */}
        <Card>
          <Icon
            backgroundColor="var(--eds-color-black)"
            color="var(--eds-color-white)"
            backgroundShape="circle"
            size="l"
            name="Collapse"
            enableFocus
          />
          <p className="eds-spacing--py-20">Generated Fields</p>
          <div className="eds-grid eds-grid--m-cols-2 eds-grid--items-align-center">
            <h6>Transfer Type</h6>
            <ul>
              <ol>Pre-Defined hard coded dropdown list</ol>
            </ul>
            <h6>Due Date</h6>
            <ul>
              <ol>Automatically pre-populated to 1 week from current date</ol>
            </ul>
            <h6>Season</h6>
            <ul>
              <ol>
                For SA, Aggerate of all unique seasons from the product index(LP
                Data from MMX)
              </ol>
            </ul>
            <h6>Product Code</h6>
            <ul>
              <ol>Combination of Style Number and Colorway Code</ol>
            </ul>
            <h6>Style Number</h6>
            <ul>
              <ol>
                Aggerate of all unique style numbers from the product index(LP
                Data from MMX)
              </ol>
            </ul>
            <h6>Product Colorway Code</h6>
            <ul>
              <ol>
                This is a drop down list of valid colorway codes for the season
                and style selected coming from the product index for SA and from
                the sourcing index for factory users
              </ol>
            </ul>
            <h6>Style Name</h6>
            <ul>
              <ol>
                Auto derived from either product code or style number entry
              </ol>
            </ul>
            <h6>Planning Product Group</h6>
            <ul>
              <ol>
                Derived from product index by passing in style number using the
                following logic and static table provided from the business for
                mapping to PPG.
              </ol>
            </ul>
            <h6>Outsole</h6>
            <ul>
              <ol>
                Derived on demand from PCX BOM via NDF call by passing either
                product code or (style number and colorway code)
              </ol>
            </ul>
            <h6>To Factory</h6>
            <ul>
              <ol>
                This is a special dropdown select list that shows two lists to
                select from, the first list should list all factories currently
                assigned to make the product based on the source index data and
                the routing rules for that product. These factories should also
                list meta data associated to the applicable routing rules so
                users can select the correct factory for the transfer. All
                factories that meet the criteria of the routing rules should be
                listed on the top list. Below the line should be the second
                list. The second list should be a complete list of ALL Finished
                Goods FW factory codes from the Aravo list of active factories.
              </ol>
            </ul>
            <h6>From Factory</h6>
            <ul>
              <ol>
                This is a special dropdown select list that shows two lists to
                select from, the first list should list all factories most
                recently assigned to make the product based on the source index
                data and the routing rules for that product. These factories
                should also list meta data associated to the applicable routing
                rules so users can select the correct factory for the transfer.
                All factories that meet the criteria of the routing rules should
                be listed on the top list. Below the line should be the second
                list. The second list should be a complete list of ALL Finished
                Goods FW factory codes from the Aravo list of active factories.
              </ol>
            </ul>
            <h6>To Factory Group</h6>
            <ul>
              <ol>
                This field is auto-derived from the Factory to Factory Group
                relationship defined in Aravo
              </ol>
            </ul>
            <h6>From Factory Group</h6>
            <ul>
              <ol>
                This field is auto-derived from the Factory to Factory Group
                relationship defined in Aravo
              </ol>
            </ul>
            <h6>Product Creation Center Code</h6>
            <ul>
              <ol>
                Derived on demand from PCX via PDH call by passing in product
                offering ID from using either product code or (style number and
                colorway code) entry to derive the product offering ID
              </ol>
            </ul>
            <h6>Transfer Notes</h6>
            <ul>
              <ol>Open text box with 500 character limit</ol>
            </ul>
            <h6>Engineering Transfer Type</h6>
            <ul>
              <ol>Pre-Defined hard coded dropdown list</ol>
            </ul>
            <h6>Transfer Reason</h6>
            <ul>
              <ol>Pre-Defined hard coded dropdown list</ol>
            </ul>
            <h6>Transfer GEOs</h6>
            <ul>
              <ol>Pre-Defined hard coded dropdown list</ol>
            </ul>
            <h6>TD Code</h6>
            <ul>
              <ol>
                Derived on demand from PCX via PDH call by passing in product
                offering ID from using either product code or (style number and
                colorway code) entry to derive the product offering ID
              </ol>
            </ul>
          </div>
        </Card>
      </Box>
    </div>
  );
};

export default Help;
