import { TextField } from '@nike/eds';
import { FC, useEffect } from 'react';
import { ITransferData } from '../NewTranferInterface';
import { useAppSelector } from '../../../redux/hooks';

interface FieldProps {
  transferData: any | ITransferData;
  setTransferData: (params: any) => any;
}

const TdCode: FC<FieldProps> = ({ transferData, setTransferData }) => {
  const [pccCodesData] = useAppSelector(state => [state.newTransfer.pccCodes]);

  useEffect(() => {
    if (pccCodesData?.tdCodes.length === 0) {
      setTransferData({
        ...transferData,
        tdCode: '',
      });
    } else {
      setTransferData({
        ...transferData,
        tdCode: pccCodesData?.tdCodes?.[0],
      });
    }
  }, [pccCodesData]);

  return (
    <TextField
      label="TD Code"
      type="text"
      value={transferData.tdCode}
      disabled
      id="td-code-group-input"
    />
  );
};

export default TdCode;
