import { BrowserRouter, Routes, Route } from 'react-router-dom';

import {
  AegisClient,
  useAuth,
  selectUsername,
  useAuthStore,
} from '@nike/aegis-auth-react';
import { Spinner } from '@nike/eds';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import { useCallback, useEffect, useState } from 'react';
import Header from '../components/common/Header';
import Dashboard from '../components/dashboard/Dashboard';
import NewTransfer from '../components/newTransfer/NewTransfer';
import TransferView from '../components/transferView/TransferView';
import Help from '../components/help/Help';
import config from '../config/config';
import routes from './routes';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import Loading from '../components/common/Loading';
import {
  clearGridColumnState,
  clearGridFilterModel,
  getGridFilterColumnModel,
} from '../redux/actions/userActionActions';

const newOktaClient = {
  clientId: config.oAuth.client_id,
  redirectUri: window.location.origin,
  qa: config.oAuth.qa,
};
const aegisClient = new AegisClient(newOktaClient);

const App = () => {
  const { loginComplete, isLoggedIn } = useAuth(aegisClient);
  const username = selectUsername(useAuthStore.getState()) || '';
  const storeData = useAuthStore.getState();
  const idClaims = { groups: [''], ...storeData.idClaims };
  const [, setState] = useState({
    isPending: true,
    loggedIn: false,
    email: null,
    username: '',
  });
  const { client } = useAuth(aegisClient);
  const dispatch = useAppDispatch();

  const [
    isLoadingItemComplete,
    isLoadingComplete,
    isLoadingCreateComplete,
    isLoadingDeleteComplete,
  ] = useAppSelector(state => [
    state.transferRecordItem.isLoadingScreen,
    state.transferRecords.isLoadingScreen,
    state.newTransfer.isLoadingScreen,
    state.transferDelete.deleteLoading,
  ]);

  const handleLogout = useCallback(() => {
    if (client !== null) {
      client.logout();
    }
    window.location.href = config.logout;
    setState({
      loggedIn: false,
      email: null,
      isPending: false,
      username: '',
    });
  }, []);

  useEffect(() => {
    dispatch<any>(getGridFilterColumnModel());
  }, []);

  const clearPreferences = useCallback(() => {
    dispatch<any>(clearGridColumnState());
    dispatch<any>(clearGridFilterModel());
  }, []);

  if (!loginComplete)
    return (
      <div className="ScreenContainer Overlay flexContainer">
        <div className="flexItemSpinner">
          <Spinner size="large" />
        </div>
      </div>
    );
  if (!isLoggedIn)
    return (
      <div className="ScreenContainer Overlay flexContainer">
        <div className="flexItem">
          <h3 className="eds-type--display-5"> WARNING</h3>
          <p> User Not Logged in</p>
        </div>
      </div>
    );
  if (idClaims.groups[0] === '')
    return (
      <div className="ScreenContainer Overlay flexContainer">
        <div className="flexItem">
          <h3 className="eds-type--display-5"> WARNING</h3>
          <p>
            You do not have permission to access this application, please
            contact system administrator
          </p>
        </div>
      </div>
    );
  return (
    <div>
      <div className="App">
        <BrowserRouter>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar
            closeOnClick
            draggable
            pauseOnHover
            theme="colored"
          />
          <div
            className="AppLayout"
            style={{ width: '100%', overflowX: 'hidden' }}
          >
            <div className="pageLayout">
              <Header
                appName={config.appName}
                username={username}
                routes={routes}
                handleLogout={handleLogout}
                clearPreferences={clearPreferences}
              />

              <main className="content">
                {!isLoadingItemComplete || <Loading full />}
                {!isLoadingComplete || <Loading full />}
                {!isLoadingCreateComplete || <Loading full />}
                {!isLoadingDeleteComplete || <Loading full />}

                <div>
                  <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/new" element={<NewTransfer />} />
                    <Route path="/help" element={<Help />} />
                    <Route
                      path="/transfer-view/:id"
                      element={<TransferView />}
                    />
                  </Routes>
                </div>
              </main>
            </div>
          </div>
        </BrowserRouter>
      </div>
    </div>
  );
};

export default App;
