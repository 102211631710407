import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import {
  USER_ROLE_LOADING_END,
  USER_ROLE_LOADING_START,
  USER_ROLE_LOADING_SUCCESS,
  UserRoleDispatchType,
  UserIdDispatchType,
  UserFactoryDispatchType,
  ResponseCode,
  USER_FACTORY_LOADING_END,
  USER_FACTORY_LOADING_START,
  USER_FACTORY_LOADING_SUCCESS,
  USER_ID_LOADING_SUCCESS,
  USER_ID_LOADING_START,
  USER_ID_LOADING_END,
  SAVE_GRID_COLUMN_MODEL,
  SAVE_GRID_FILTER_MODEL,
  UserFilterDispatchType,
  SAVE_GRID_FILTER_MODEL_START,
  SAVE_GRID_FILTER_MODEL_END,
} from '../types/userActionTypes';
import apiConfig from '../../config/apiConfig';
import apiInstance from '../axios/index';

import { fetchUserGroupName, fetchUserID } from '../../utils/sttToken';

export const fetchUserRole =
  () => async (dispatch: Dispatch<UserRoleDispatchType>) => {
    dispatch({ type: USER_ROLE_LOADING_START });
    try {
      const userRoleName = await fetchUserGroupName();
      dispatch({
        type: USER_ROLE_LOADING_SUCCESS,
        payload: userRoleName,
      });
      dispatch({ type: USER_ROLE_LOADING_END });
    } catch (err) {
      toast.error('Something went wrong. Could not get user role');
      dispatch({ type: USER_ROLE_LOADING_END });
    }
  };

export const fetchUserIdentifier =
  () => async (dispatch: Dispatch<UserIdDispatchType>) => {
    dispatch({ type: USER_ID_LOADING_START });
    try {
      const id = await fetchUserID();
      dispatch({
        type: USER_ID_LOADING_SUCCESS,
        payload: id,
      });
      dispatch({ type: USER_ID_LOADING_END });
    } catch (err) {
      toast.error('Something went wrong. Could not get user id');
      dispatch({ type: USER_ID_LOADING_END });
    }
  };

export const fetchUserFactorys =
  (params?: any) => async (dispatch: Dispatch<UserFactoryDispatchType>) => {
    dispatch({ type: USER_FACTORY_LOADING_START });
    try {
      const getFactoryAssignmentUrl =
        apiConfig.baseURL + apiConfig.getFactoryAssignments.url;
      const response = await apiInstance.get(`${getFactoryAssignmentUrl}`, {
        params,
      });
      if (response && response.status === ResponseCode.OKAY) {
        dispatch({
          type: USER_FACTORY_LOADING_SUCCESS,
          payload:
            response?.data?.data?.association?.length > 0
              ? response?.data?.data?.association[0].factories
              : [],
        });
        dispatch({ type: USER_FACTORY_LOADING_END });
      }
    } catch (err) {
      toast.error('Something went wrong. Could not get Factory Assignments');
      dispatch({ type: USER_FACTORY_LOADING_END });
    }
  };

export const getGridFilterColumnModel =
  () => async (dispatch: Dispatch<UserFilterDispatchType>) => {
    dispatch({ type: SAVE_GRID_FILTER_MODEL_START });
    try {
      const getGridPreferences =
        apiConfig.baseURL + apiConfig.getUserGridPreferences.url;

      const response = await apiInstance.get(`${getGridPreferences}`);

      if (response.status === ResponseCode.OKAY) {
        dispatch({
          type: SAVE_GRID_FILTER_MODEL,
          payload: response.data.response.filterState,
        });
        dispatch({
          type: SAVE_GRID_COLUMN_MODEL,
          payload: response.data.response.columnState,
        });
      }
      dispatch({ type: SAVE_GRID_FILTER_MODEL_END });
    } catch (err: any) {
      if (err) {
        if (err.response.status !== ResponseCode.NOT_FOUND) {
          toast.error('Could not get user preferences');
        }
      }
      dispatch({ type: SAVE_GRID_FILTER_MODEL_END });
    }
  };

export const saveGridFilterColumnModel =
  (filterState?: any, columnState?: any) =>
  async (dispatch: Dispatch<UserFilterDispatchType>) => {
    dispatch({ type: SAVE_GRID_FILTER_MODEL_START });
    try {
      const updateGridPreferences =
        apiConfig.baseURL + apiConfig.updateUserGridPreferences.url;

      const id = await fetchUserID();

      const params = {
        id,
        columnState,
        filterState,
        modifiedOn: new Date(),
        createdOn: new Date(),
      };

      const response = await apiInstance.put(
        `${updateGridPreferences}`,
        params
      );

      if (response.status === ResponseCode.OKAY) {
        dispatch({
          type: SAVE_GRID_FILTER_MODEL,
          payload: params.filterState,
        });
        dispatch({
          type: SAVE_GRID_COLUMN_MODEL,
          payload: params.columnState,
        });
      }
      dispatch({ type: SAVE_GRID_FILTER_MODEL_END });
    } catch (err: any) {
      if (err) {
        if (err.response.status !== ResponseCode.NOT_FOUND) {
          toast.error('Could not save user preferences');
        }
      }
      dispatch({ type: SAVE_GRID_FILTER_MODEL_END });
    }
  };

export const clearGridColumnState =
  () => async (dispatch: Dispatch<UserFilterDispatchType>) => {
    dispatch({
      type: SAVE_GRID_COLUMN_MODEL,
      payload: [],
    });
  };

export const clearGridFilterModel =
  () => async (dispatch: Dispatch<UserFilterDispatchType>) => {
    dispatch({
      type: SAVE_GRID_FILTER_MODEL,
      payload: {},
    });
  };
