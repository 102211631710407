import moment from 'moment';
import GridLinksCellRenderer from './GridLinksCellRenderer';
import StatusCellRenderer from './StatusCellRenderer';
// import ViewCustomTooltip from './ViewCustomTooltip';
import { roles } from '../common/RolesConstants';

const dateFilterParams = {
  // eslint-disable-next-line consistent-return
  comparator: (filterLocalDateAtMidnight: string, cellValue: string) => {
    const dateAsString = cellValue;
    if (dateAsString == null) return -1;
    const momentdate = moment(cellValue).format('MM/DD/YYYY');
    const cellDate = Date.parse(momentdate);
    const filterdate = Date.parse(filterLocalDateAtMidnight);
    if (filterdate === cellDate) {
      return 0;
    }
    if (cellDate < filterdate) {
      return -1;
    }
    if (cellDate > filterdate) {
      return 1;
    }
  },
  browserDatePicker: true,
};

const dashboardGridColumns = (userRole: string) => {
  const columns = [
    {
      field: 'checkBox',
      headerName: '',
      checkboxSelection: true,
      width: 70,
      minWidth: 30,
      floatingFilter: false,
      cellClass: 'eds-spacing--pl-24',
      headerClass: 'eds-spacing--pl-24',
      resizable: false,
      menuTabs: [],
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: 'View',
      field: '_id',
      cellRenderer: GridLinksCellRenderer,
      width: 70,
      minWidth: 70,
      floatingFilter: false,
      cellClass: 'eds-spacing--pt-4',
      resizable: false,
      tooltipField: '_id',
      menuTabs: [],
      sortable: false,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: 'Flag',
      field: 'importantFlag',
      cellRenderer: GridLinksCellRenderer,
      width: 90,
      minWidth: 90,
      resizable: false,
      floatingFilter: false,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: [true, false],
        valueFormatter: (params: { value: string | boolean }) => {
          return params.value === true || params.value === 'true'
            ? 'Yes'
            : 'No';
        },
      },
    },
    {
      headerName: 'Status',
      field: 'status',
      cellRenderer: StatusCellRenderer,
      width: 120,
      minWidth: 120,
      filterParams: {
        values: ['Draft', 'Factory', 'Confirmed', 'Cancelled', 'Sourcing'],
      },
    },
    {
      headerName: 'Transfer Type',
      field: 'transferType',
      width: 190,
      minWidth: 190,
    },
    {
      headerName: 'Modified Time',
      field: 'modifiedTimestamp',
      filter: 'agDateColumnFilter',
      width: 160,
      minWidth: 160,
      filterParams: dateFilterParams,
      cellRenderer: (params: { value: moment.MomentInput }) =>
        moment.utc(params.value).local().format('DD-MMM-YYYY LT'),
    },
    {
      headerName: 'Due Date',
      field: 'dueDate',
      filter: 'agDateColumnFilter',
      filterParams: dateFilterParams,
      cellRenderer: (params: { value: moment.MomentInput }) =>
        moment.utc(params.value).local().format('DD-MMM-YYYY'),
      width: 110,
      minWidth: 110,
    },
    {
      headerName: 'Season Year',
      field: 'seasonYearCode',
      width: 110,
      minWidth: 110,
    },
    { headerName: 'PPG', field: 'productPlanningGroup' },
    {
      headerName: 'Style Name',
      field: 'styleName',
      filter: 'agTextColumnFilter',
      minWidth: 200,
    },
    {
      headerName: 'Product Code',
      field: 'productCode',
      filter: 'agTextColumnFilter',
      width: 120,
      minWidth: 120,
    },
    {
      headerName: 'From Fct',
      field: 'fromFactoryCode',
      width: 105,
      minWidth: 105,
    },
    { headerName: 'To Fct', field: 'toFactoryCode', width: 100, minWidth: 100 },
    {
      headerName: 'Outsole Code',
      field: 'outsoleCode',
      width: 115,
      minWidth: 115,
    },
    {
      headerName: 'Confirmed Date',
      field: 'confirmedDate',
      filter: 'agDateColumnFilter',
      width: 130,
      minWidth: 130,
      filterParams: dateFilterParams,
      cellRenderer: (params: { value: moment.MomentInput }) =>
        params.value
          ? moment.utc(params.value).local().format('DD-MMM-YYYY')
          : '',
    },
    {
      headerName: 'Cancelled Date',
      field: 'cancelledDate',
      filter: 'agDateColumnFilter',
      width: 130,
      minWidth: 130,
      filterParams: dateFilterParams,
      cellRenderer: (params: { value: moment.MomentInput }) =>
        params.value
          ? moment.utc(params.value).local().format('DD-MMM-YYYY')
          : '',
    },
    { headerName: 'Style', field: 'styleNumber', width: 100, minWidth: 100 },
    { headerName: 'Clr', field: 'colorwayCode', width: 60, minWidth: 60 },
    { headerName: 'Model Name', field: 'modelName', width: 200, minWidth: 200 },
    {
      headerName: 'PCC',
      field: 'productCreationCenterCode',
      width: 70,
      minWidth: 70,
    },
    {
      headerName: 'Transfer Notes',
      field: 'transferNotes',
      width: 115,
      minWidth: 115,
    },
    {
      headerName: 'Transfer Reason',
      field: 'transferReason',
      width: 115,
      minWidth: 115,
    },
    {
      headerName: 'Engineering Type',
      field: 'engineeringType',
      width: 130,
      minWidth: 130,
    },
    { headerName: 'Td Code', field: 'tdCode' },
    {
      headerName: 'From Fct Grp',
      field: 'fromFactoryGroupCode',
      width: 100,
      minWidth: 100,
    },
    {
      headerName: 'To Fct Grp',
      field: 'toFactoryGroupCode',
      width: 100,
      minWidth: 100,
    },
    {
      headerName: 'Attachments',
      field: 'attachments',
      width: 130,
      minWidth: 130,
      filterParams: {
        values: ['Yes', 'No'],
      },
      // eslint-disable-next-line consistent-return
      cellRenderer: (params: { value: any }) => {
        if (params.value !== undefined) {
          if (params.value === 'yes') {
            return 'Yes';
          }
          if (Array.isArray(params.value))
            if (params.value.length > 0) {
              return 'Yes';
            }
        } else {
          return '';
        }
      },
    },
    {
      headerName: 'From Factory Comments',
      field: 'FromFactoryComments',
      tooltipField: 'FromFactoryComments',
    },
    {
      headerName: 'To Factory Comments',
      field: 'ToFactoryComments',
      tooltipField: 'ToFactoryComments',
    },

    {
      headerName: 'WHQ Comments',
      field: 'WHQComments',
      tooltipField: 'WHQComments',
    },
    {
      headerName: 'PMO Comments',
      field: 'PMOComments',
      tooltipField: 'PMOComments',
    },
    {
      headerName: 'Create Date',
      field: 'createdTimestamp',
      filter: 'agDateColumnFilter',
      filterParams: dateFilterParams,
      width: 130,
      minWidth: 130,
      cellRenderer: (params: { value: moment.MomentInput }) =>
        params.value
          ? moment.utc(params.value).local().format('DD-MMM-YYYY')
          : '',
    },
    { headerName: 'Created By', field: 'createdBy', width: 220, minWidth: 220 },
    {
      headerName: 'Modified By',
      field: 'modifiedBy',
      width: 220,
      minWidth: 220,
    },
  ];
  if (userRole && userRole !== roles.analyst) {
    return columns.filter(x => x.field !== 'checkBox');
  }
  return columns;
};
export default dashboardGridColumns;
