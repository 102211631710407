/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-underscore-dangle */
import { FC, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, TabGroup, Tab } from '@nike/eds';
import { CaretLeft } from '@nike/nike-design-system-icons';
import moment from 'moment';
import { toast } from 'react-toastify';
import TransferPrimaryDataBox from './TransferPrimaryDataBox';
import CommentsTab from './CommentsTab';
import AttachmentsTab from './AttachmentsTab';
import ChangeLogTab from './ChangeLogTab';
import TransferTitle from './TransferTitle';
import Loading from '../common/Loading';
import {
  roles,
  TRANSFER_STATUS_SOURCING,
  TRANSFER_STATUS_FACTORY,
  TRANSFER_STATUS_CONFIRMED,
  TRANSFER_STATUS_DRAFT,
  TRANSFER_STATUS_CANCELLED,
  API_TRANSFER_STATUS_SOURCING,
  API_TRANSFER_STATUS_FACTORY,
  API_TRANSFER_STATUS_CONFIRM,
  API_TRANSFER_STATUS_CANCEL,
  API_TRANSFER_STATUS_DRAFT,
} from '../common/RolesConstants';
import {
  ITransferData,
  TRANSFER_TYPE_EXISTING,
  TRANSFER_TYPE_MODEL_COMPLETE,
  TRANSFER_TYPE_NEW,
  TRANSFER_TYPE_OUTSOLE_COMPLETE,
  TRANSFER_TYPE_TECH_PACK,
  TRANSFER_TYPE_TOOLING,
  TRANSFER_TYPE_COMPLETE,
  transferInitialState,
} from '../newTransfer/NewTranferInterface';
import './TransferView.scss';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  fetchTransferRecordItem,
  updateTransferData,
  updateTransferRecordItemStatus,
} from '../../redux/actions/transferRecordItemActions';
import Form from '../newTransfer/Form';
import { PostTransferData } from '../../redux/types/newTransferTypes';
import DeleteConfirmModal from './DeleteConfirmModal';
import {
  deleteTransferRecord,
  deleteTransferRecordReset,
} from '../../redux/actions/deleteTransferRecordActions';
import ActionConfirmModal from '../dashboard/ActionConfirmModal';
import ActionFailureModal from '../dashboard/ActionFailureModal';
import { ISelectionAction } from '../../redux/types/transferRecordsTypes';
import {
  transferDeleteAttachmentReset,
  transferUploadAttachmentReset,
} from '../../redux/actions/transferAttachmentActions';
import { hideError } from '../../redux/actions/errorActions';
import fixColorwayCodeArray from '../../utils/saveTransfer';
import {
  updateEditMode,
  updateInputTouched,
} from '../../redux/actions/inputActions';
import { fetchUserFactorys } from '../../redux/actions/userActionActions';

const tViewTabs = [
  { value: 'transferCommentsTab', label: 'Comments' },
  { value: 'transferAttachmentTab', label: 'Attachments' },
  { value: 'transferChangeLogTab', label: 'Change Log' },
];

const TransferView: FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('transferCommentsTab');
  const [transferData, setTransferData] =
    useState<ITransferData>(transferInitialState);
  const [isDeleteConfirmModalOpen, setDeleteIsConfirmModalOpen] =
    useState<boolean>(false);
  const [isActionConfirmModalOpen, setIsActionConfirmModalOpen] =
    useState<boolean>(false);
  const [isActionFailureModalOpen, setIsActionFailureModalOpen] =
    useState<boolean>(false);
  const [actionToConfirm, setActionToConfirm] =
    useState<ISelectionAction | null>(null);
  const [isOpenTransferDataValid, setIsOpenTransferDataValid] =
    useState<boolean>(false);
  const [isConfirmTransferDataValid, setIsConfirmTransferDataValid] =
    useState<boolean>(false);
  const [isOlderThanSixMonths, setIsOlderThanSixMonths] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();

  const [
    transferItemLoading,
    transferItemSuccess,
    transferItemData,
    userRole,
    modelName,
    productOfferingIdentifier,
    pccCodesData,
    styleName,
    flagStatus,
    transferId,
    updateTransferStatus,
    transferAttachmentsData,
    pccLoading,
    outsoleLoading,
    deleteSuccess,
    deleteLoading,
    isEditMode,
    inputTouched,
    hasBlockingErrors,
    factoryAssignments,
  ] = useAppSelector(state => [
    state.transferRecordItem.transferItemLoading,
    state.transferRecordItem.transferItemSuccess,
    state.transferRecordItem.transferItemData.source,
    state.userRole.role,
    state.newTransfer.productInfo?._source.modelName,
    state.newTransfer.productInfo?._source.productOfferingIdentifier,
    state.newTransfer.pccCodes,
    state.newTransfer.styleName,
    state.transferRecordItem.transferItemData.source?.importantFlag,
    state.transferRecordItem.transferItemData.source?.transferId,
    state.updateTransferStatus,
    state.transferAttachments,
    state.newTransfer.pccLoading,
    state.newTransfer.loading,
    state.transferDelete.deleteSuccess,
    state.transferDelete.deleteLoading,
    state.input.isEditMode,
    state.input.isTouched,
    state.error.hasBlockingError,
    state.userAssignments.factories,
  ]);

  /*
  - State variable ( enableAutoPopulateFields ) to decide whether we need to Auto Derive form fields values
  by the API call on EDIT Screen, It would be FALSE when 
  - for both a Factory and PMO users, the API calls should not be made to update 
   the  derived fields when a TR is in a status of Confirmed, Sourcing and Cancelled 
  - APIs need to be disabled so these fields do not change.

  - For all the Other cases it would be TRUE 
  */
  const [enableAutoPopulateFields, setEnableAutoPopulateFields] =
    useState<boolean>(true);

  useEffect(() => {
    if (transferItemData.status) {
      setEnableAutoPopulateFields(
        !(
          (userRole === roles.factory || userRole === roles.pmo) &&
          (transferItemData.status === TRANSFER_STATUS_CONFIRMED ||
            transferItemData.status === TRANSFER_STATUS_SOURCING ||
            transferItemData.status === TRANSFER_STATUS_CANCELLED)
        )
      );
    }
  }, [transferItemData.status]);

  useEffect(() => {
    dispatch<any>(fetchUserFactorys(userRole));
  }, []);

  useEffect(() => {
    // factory or sourcing
    if (
      !isEditMode &&
      (transferItemData?.transferType === TRANSFER_TYPE_TECH_PACK ||
        transferItemData?.transferType === TRANSFER_TYPE_EXISTING ||
        transferItemData?.transferType === TRANSFER_TYPE_MODEL_COMPLETE ||
        transferData.transferType?.value === TRANSFER_TYPE_COMPLETE ||
        transferItemData?.transferType === TRANSFER_TYPE_NEW) &&
      transferItemData?.seasonYearCode &&
      (transferItemData?.productCode || transferItemData.styleNumber) &&
      transferItemData?.productPlanningGroup &&
      transferItemData?.toFactoryCode
    ) {
      setIsOpenTransferDataValid(true);
    } else if (
      !isEditMode &&
      (transferItemData?.transferType === TRANSFER_TYPE_TOOLING ||
        transferItemData?.transferType === TRANSFER_TYPE_OUTSOLE_COMPLETE) &&
      transferItemData?.seasonYearCode &&
      transferItemData?.productPlanningGroup &&
      (transferItemData?.outsoleCode !== '' ||
        transferItemData?.outsoleCode !== undefined) &&
      transferItemData?.toFactoryCode
    ) {
      setIsOpenTransferDataValid(true);
    } else {
      setIsOpenTransferDataValid(false);
    }

    // confirm
    if (
      !isEditMode &&
      (transferItemData?.transferType === TRANSFER_TYPE_EXISTING ||
        transferItemData?.transferType === TRANSFER_TYPE_NEW) &&
      transferItemData?.seasonYearCode &&
      (transferItemData?.styleNumber || transferItemData?.productCode) &&
      transferItemData?.productCreationCenterCode !== '' &&
      transferItemData?.productCreationCenterCode !== undefined &&
      transferItemData?.productPlanningGroup &&
      transferItemData?.outsoleCode !== '' &&
      transferItemData?.outsoleCode !== undefined &&
      transferItemData?.toFactoryCode &&
      transferItemData?.fromFactoryCode &&
      userRole === roles.analyst
    ) {
      setIsConfirmTransferDataValid(true);
    } else if (
      !isEditMode &&
      (transferItemData?.transferType === TRANSFER_TYPE_TECH_PACK ||
        transferItemData?.transferType === TRANSFER_TYPE_MODEL_COMPLETE ||
        transferData.transferType?.value === TRANSFER_TYPE_COMPLETE) &&
      transferItemData?.seasonYearCode &&
      (transferItemData?.styleNumber || transferItemData?.productCode) &&
      transferItemData?.productCreationCenterCode !== '' &&
      transferItemData?.productCreationCenterCode !== undefined &&
      transferItemData?.productPlanningGroup &&
      transferItemData?.toFactoryCode &&
      transferItemData?.fromFactoryCode &&
      userRole === roles.analyst
    ) {
      setIsConfirmTransferDataValid(true);
    } else if (
      !isEditMode &&
      transferItemData?.transferType === TRANSFER_TYPE_TOOLING &&
      transferItemData?.seasonYearCode &&
      transferItemData?.productPlanningGroup &&
      transferItemData?.toFactoryCode &&
      transferItemData?.fromFactoryCode &&
      transferItemData?.outsoleCode !== '' &&
      transferItemData?.outsoleCode !== undefined &&
      userRole === roles.analyst
    ) {
      setIsConfirmTransferDataValid(true);
    } else if (
      !isEditMode &&
      transferItemData?.transferType === TRANSFER_TYPE_OUTSOLE_COMPLETE &&
      transferItemData?.seasonYearCode &&
      transferItemData?.productPlanningGroup &&
      transferItemData?.toFactoryCode &&
      transferItemData?.fromFactoryCode &&
      transferItemData?.outsoleCode !== '' &&
      transferItemData?.outsoleCode !== undefined &&
      userRole === roles.analyst
    ) {
      setIsConfirmTransferDataValid(true);
    } else {
      setIsConfirmTransferDataValid(false);
    }

    // if form have a blocking error
    if (hasBlockingErrors) {
      setIsConfirmTransferDataValid(false);
      setIsOpenTransferDataValid(false);
    }
  }, [transferData, transferItemData, isEditMode]);

  useEffect(() => {
    if (
      transferItemData !== undefined &&
      transferItemData !== null &&
      isEditMode
    ) {
      const getColorway = !Array.isArray(transferItemData.colorwayCode)
        ? {
            value: transferItemData.colorwayCode || '',
            label: transferItemData.colorwayCode || '',
          }
        : {
            value: transferItemData.colorwayCode[0] || '',
            label: transferItemData.colorwayCode[0] || '',
          };
      if (Object.keys(transferItemData).length !== 0) {
        setTransferData({
          ...transferData,
          dueDate: transferItemData.dueDate!,
          transferGeos: transferItemData.transferGeos || [],
          transferType: transferItemData.transferType
            ? {
                value: transferItemData.transferType,
                label: transferItemData.transferType,
              }
            : null,
          season: transferItemData.seasonYearCode
            ? {
                value: transferItemData.seasonYearCode,
                label: transferItemData.seasonYearCode,
              }
            : null,
          productCode:
            transferItemData.productCode !== null
              ? {
                  value: transferItemData.productCode,
                  label: transferItemData.productCode,
                }
              : null,
          styleNumber: transferItemData.styleNumber
            ? {
                value: transferItemData.styleNumber,
                label: transferItemData.styleNumber,
              }
            : null,
          styleName: transferItemData.styleName || '',
          transferNotes: transferItemData.transferNotes || '',
          productColorwayCode: getColorway,
          toFactory: transferItemData.toFactoryCode
            ? {
                value: transferItemData.toFactoryCode,
                label: transferItemData.toFactoryCode,
              }
            : null,
          fromFactory: transferItemData.fromFactoryCode
            ? {
                value: transferItemData.fromFactoryCode,
                label: transferItemData.fromFactoryCode,
              }
            : null,
          toFactoryGroup: transferItemData.toFactoryGroupCode || '',
          fromFactoryGroup: transferItemData.fromFactoryGroupCode || '',
          engineeringTransferType: transferItemData.engineeringType
            ? {
                value: transferItemData.engineeringType,
                label: transferItemData.engineeringType,
              }
            : null,
          transferReason: transferItemData.transferReason
            ? {
                value: transferItemData.transferReason,
                label: transferItemData.transferReason,
              }
            : null,
          outsole: transferItemData.outsoleCode,
          productPlanningGroup: transferItemData.productPlanningGroup
            ? {
                value: transferItemData.productPlanningGroup,
                label: transferItemData.productPlanningGroup,
              }
            : null,
          pccCodes: transferItemData.productCreationCenterCode,
          tdCode: transferItemData.tdCode,
        });
      }
    }
  }, [transferItemData, isEditMode]);

  useEffect(() => {
    dispatch<any>(transferUploadAttachmentReset());
    dispatch<any>(transferDeleteAttachmentReset());
  }, [
    transferAttachmentsData.uploadTransferAttachmentData,
    transferAttachmentsData.uploadTransferAttachmentError,
    transferAttachmentsData.deleteTransferAttachmentData,
    transferAttachmentsData.deleteTransferAttachmentError,
  ]);

  useEffect(() => {
    dispatch<any>(fetchTransferRecordItem(params.id));

    // disable edit button if create date is over 6 months old
    if (
      moment(transferItemData.createdTimestamp).isSameOrBefore(
        moment().subtract(6, 'months')
      )
    ) {
      toast.warn(
        'This Transfer Record is older than 6 months old and cannot be edited'
      );
      setIsOlderThanSixMonths(true);
    }

    dispatch<any>(updateEditMode(false));
  }, [transferItemData.createdTimestamp]);

  const clickCancel = () => {
    navigate('/');
    dispatch<any>(updateEditMode(false));
  };

  const clickDuplicate = () => {
    navigate('/new', { state: { transferId: params.id } });
  };

  const handleSaveTransferData = (status: string | undefined) => {
    if (status !== undefined && inputTouched && transferData.dueDate) {
      const transferDataPayload: PostTransferData = {
        importantFlag: flagStatus,
        styleName: enableAutoPopulateFields
          ? styleName
          : transferData.styleName,
        colorwayCode: fixColorwayCodeArray(transferData.productColorwayCode),
        seasonYearCode: transferData.season?.value || '',
        productCode: transferData.productCode?.value || '',
        styleNumber: transferData.styleNumber?.value || '',
        transferNotes: transferData.transferNotes,
        dueDate: transferData.dueDate || new Date(),
        productPlanningGroup: transferData.productPlanningGroup?.value || '',
        engineeringType: transferData.engineeringTransferType?.value || '',
        fromFactoryCode: transferData.fromFactory?.value || '',
        toFactoryCode: transferData.toFactory?.value || '',
        transferGeos: transferData.transferGeos,
        transferReason: transferData.transferReason?.value || '',
        transferType: transferData.transferType?.value || '',
        outsoleCode: transferData.outsole || '',
        toFactoryGroupCode: transferData.toFactoryGroup || '',
        fromFactoryGroupCode: transferData.fromFactoryGroup || '',
        productCreationCenterCode: transferData.pccCodes || '',
        tdCode:
          (enableAutoPopulateFields
            ? pccCodesData?.tdCodes[0]
            : transferData.tdCode) || '',
        status,
        createdBy: transferItemData.createdBy || '',
        createdTimestamp: new Date().toISOString().slice(0, -5),
        modelName,
        productOfferingIdentifier,
      };
      if (transferId) {
        dispatch<any>(updateInputTouched(false));
        dispatch<any>(updateTransferData(transferId, transferDataPayload));
      }
    }
  };

  const renderTab = (tab: string) => {
    switch (tab) {
      case 'transferCommentsTab':
        return <CommentsTab transferId={transferItemData?.transferId} />;
      case 'transferAttachmentTab':
        return <AttachmentsTab attachments={transferItemData?.attachments} />;
      case 'transferChangeLogTab':
        return <ChangeLogTab changeLog={transferItemData?.changeLog} />;

      default:
        return <span> </span>;
    }
  };

  const onDeleteTransferModalClose = () => {
    setDeleteIsConfirmModalOpen(false);
  };

  const onDeleteTransferModalConfirm = () => {
    dispatch<any>(deleteTransferRecord(params.id));
    setDeleteIsConfirmModalOpen(false);
  };

  useEffect(() => {
    if (!deleteLoading) {
      if (deleteSuccess) {
        navigate('/'); // on success save data navigating to dashboard
        dispatch<any>(deleteTransferRecordReset());
      }
    }
  }, [deleteLoading, deleteSuccess]);

  const onDeleteTransferClick = () => {
    setDeleteIsConfirmModalOpen(true);
  };

  useEffect(() => {
    if (!updateTransferStatus.loading) {
      if (updateTransferStatus.success) {
        dispatch<any>(fetchTransferRecordItem(params.id));
      } else if (updateTransferStatus.error) {
        setIsActionFailureModalOpen(true);
      }
    }
  }, [updateTransferStatus]);

  useEffect(() => {
    if (!transferItemLoading) {
      if (transferItemSuccess) {
        dispatch<any>(updateEditMode());
      }
    }
  }, [transferItemSuccess]);

  const handleClearActionToConfirm = () => {
    setActionToConfirm(null);
    setIsActionConfirmModalOpen(false);
  };

  const handleConfirmSelectionAction = () => {
    if (actionToConfirm) {
      dispatch<any>(
        updateTransferRecordItemStatus(actionToConfirm.status, {
          ids: actionToConfirm.ids,
        })
      );
      handleClearActionToConfirm();
      navigate('/'); // on navigating to dashboard
    }
  };

  const onStatusChange = (status: string) => {
    // we have to create an array before we change the status
    const transfersArray: string[] = [];
    if (transferId) {
      transfersArray.push(transferId);

      const data = { ids: transfersArray, status };
      setActionToConfirm(data);
      setIsActionConfirmModalOpen(true);
    }
  };

  const handleActionFailureClose = () => {
    dispatch<any>(fetchTransferRecordItem(params.id));
    setIsActionFailureModalOpen(false);
  };

  const handleEditModeToggle = () => {
    dispatch<any>(hideError());
    dispatch<any>(updateEditMode());
  };

  return (
    <div>
      <DeleteConfirmModal
        onDelete={onDeleteTransferModalConfirm}
        isOpen={isDeleteConfirmModalOpen}
        onDismiss={onDeleteTransferModalClose}
      />
      <ActionConfirmModal
        onConfirm={handleConfirmSelectionAction}
        isOpen={isActionConfirmModalOpen}
        onDismiss={handleClearActionToConfirm}
        actionToConfirm={actionToConfirm}
      />
      <ActionFailureModal
        isOpen={isActionFailureModalOpen}
        onDismiss={handleActionFailureClose}
        actionData={updateTransferStatus.data}
      />
      <div className="view-transfer-container">
        {/* LOADING BAR ON LANDING */}
        {transferItemLoading && <Loading full />}
        {/* LOADING BAR ON LANDING */}
        {!transferItemLoading && (
          <>
            <div className="eds-grid eds-grid--m-cols-4">
              <div className="eds-grid--m-col-1">
                <Button
                  className="eds-color--grey-7"
                  size="medium"
                  variant="ghost"
                  id="back-to-dashboard-transfer-button"
                  onClick={clickCancel}
                  beforeSlot={<CaretLeft />}
                >
                  Back to Dashboard
                </Button>
              </div>
              <div className="eds-grid--m-col-3">
                <div className="button-group eds-button-group">
                  {(transferItemData?.status === TRANSFER_STATUS_SOURCING &&
                    userRole === roles.analyst) ||
                  transferItemData?.status === TRANSFER_STATUS_DRAFT ? (
                    <Button
                      size="medium"
                      variant="secondary"
                      className="action-buttons"
                      id="delete-transfer-button"
                      onClick={onDeleteTransferClick}
                    >
                      Delete Transfer
                    </Button>
                  ) : null}
                  {userRole !== roles.planning ? (
                    <Button
                      size="medium"
                      variant="secondary"
                      className="action-buttons"
                      id="edit-undo-button"
                      onClick={() => handleEditModeToggle()}
                      disabled={isOlderThanSixMonths}
                    >
                      {isEditMode ? 'Undo' : 'Edit'}
                    </Button>
                  ) : null}
                  {isEditMode ? (
                    <Button
                      size="medium"
                      variant="secondary"
                      className="action-buttons"
                      id="Save-transfer-button"
                      onClick={() => {
                        handleSaveTransferData(transferItemData.status);
                      }}
                      disabled={pccLoading || outsoleLoading}
                    >
                      Save
                    </Button>
                  ) : null}
                  {userRole !== roles.planning && (
                    <Button
                      size="medium"
                      variant="secondary"
                      className="action-buttons"
                      id="duplicate-transfer-button"
                      onClick={clickDuplicate}
                      disabled={isEditMode}
                    >
                      Duplicate
                    </Button>
                  )}
                  <div>
                    {userRole === roles.analyst &&
                    transferItemData?.status !== TRANSFER_STATUS_CANCELLED &&
                    (transferItemData?.status === TRANSFER_STATUS_SOURCING ||
                      transferItemData?.status ===
                        TRANSFER_STATUS_CONFIRMED) ? (
                      <Button
                        size="medium"
                        className="view-state-buttons"
                        id="cancel-transfer-button"
                        onClick={() => {
                          onStatusChange(API_TRANSFER_STATUS_CANCEL);
                        }}
                        disabled={isEditMode}
                      >
                        Cancel
                      </Button>
                    ) : null}
                    {(userRole === roles.factory || userRole === roles.pmo) &&
                    transferItemData?.status !== TRANSFER_STATUS_SOURCING &&
                    (transferItemData?.status === TRANSFER_STATUS_FACTORY ||
                      transferItemData?.status === TRANSFER_STATUS_DRAFT ||
                      transferItemData?.status ===
                        TRANSFER_STATUS_CONFIRMED) ? (
                      <Button
                        size="medium"
                        className="view-state-buttons"
                        id="send-to-sourcing-button"
                        disabled={!isOpenTransferDataValid}
                        onClick={() => {
                          onStatusChange(API_TRANSFER_STATUS_SOURCING);
                        }}
                      >
                        Send to Sourcing
                      </Button>
                    ) : null}
                    {userRole === roles.analyst &&
                    transferItemData?.status !== TRANSFER_STATUS_FACTORY ? (
                      <Button
                        size="medium"
                        className="view-state-buttons"
                        id="send-to-factory-button"
                        disabled={!isOpenTransferDataValid}
                        onClick={() => {
                          onStatusChange(API_TRANSFER_STATUS_FACTORY);
                        }}
                      >
                        Send to Factory
                      </Button>
                    ) : null}
                    {(userRole === roles.pmo &&
                      transferItemData?.status === TRANSFER_STATUS_FACTORY) ||
                    (userRole === roles.factory &&
                      !factoryAssignments.includes(
                        transferItemData.toFactoryCode
                      )) ? (
                      <Button
                        size="medium"
                        className="view-state-buttons"
                        id="draft-button"
                        disabled={!isOpenTransferDataValid}
                        onClick={() => {
                          onStatusChange(API_TRANSFER_STATUS_DRAFT);
                        }}
                      >
                        Send to Draft
                      </Button>
                    ) : null}
                    {userRole === roles.analyst &&
                    transferItemData?.status !== TRANSFER_STATUS_CONFIRMED &&
                    (transferItemData?.status === TRANSFER_STATUS_CANCELLED ||
                      transferItemData?.status === TRANSFER_STATUS_DRAFT ||
                      transferItemData?.status === TRANSFER_STATUS_SOURCING) ? (
                      <Button
                        size="medium"
                        id="confirm-button"
                        className="view-state-buttons"
                        disabled={!isConfirmTransferDataValid}
                        onClick={() => {
                          onStatusChange(API_TRANSFER_STATUS_CONFIRM);
                        }}
                      >
                        Confirm
                      </Button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="eds-grid eds-grid--m-cols-4">
              <TransferTitle data={transferItemData} />
            </div>
            <div className="eds-grid">
              {isEditMode ? (
                <div className="new-transfer-container">
                  <Form
                    transferData={transferData}
                    setTransferData={setTransferData}
                    enableAutoPopulateFields={enableAutoPopulateFields}
                  />
                </div>
              ) : (
                <TransferPrimaryDataBox />
              )}
            </div>
            <div className="eds-grid eds-grid--m-cols-4">
              <div className="eds-grid--m-col-3 eds-spacing--pt-32">
                <TabGroup
                  name="tab-group"
                  defaultId="transferCommentsTab"
                  activeId={activeTab}
                  onChange={e => setActiveTab(e.target.id)}
                >
                  {tViewTabs.map(tab => (
                    <Tab key={`transferview${tab.value}`} id={tab.value}>
                      {tab.label}
                    </Tab>
                  ))}
                </TabGroup>

                {tViewTabs.map(tab => (
                  <div
                    key={`transferview${tab.value}`}
                    className={activeTab === tab.value ? 'tabShow' : 'tabHide'}
                  >
                    {renderTab(tab.value)}
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default TransferView;
