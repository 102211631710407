/* eslint-disable react/destructuring-assignment */
import { useCallback, useMemo, useRef, useState, useEffect, FC } from 'react';
import { Props as ReactSelectProps } from 'react-select';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Spinner, Select } from '@nike/eds';
import { Close } from '@nike/nike-design-system-icons';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './Dashboard.scss';
import {
  ColDef,
  SideBarDef,
  GridReadyEvent,
  IFiltersToolPanel,
  RowNode,
  ColumnState,
} from 'ag-grid-community';
import { debounce } from 'lodash';
import { sortByQuarterYear } from '../../utils/sortFunctions';
import dashboardGridColumns from './GridColumnDef';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  ISelectionAction,
  TransferRecordType,
} from '../../redux/types/transferRecordsTypes';
import { fetchTransferRecords } from '../../redux/actions/transferRecordsActions';
import {
  fetchUserRole,
  saveGridFilterColumnModel,
} from '../../redux/actions/userActionActions';

import {
  roles,
  API_TRANSFER_STATUS_CANCEL,
  API_TRANSFER_STATUS_CONFIRM,
  TRANSFER_STATUS_DRAFT,
  TRANSFER_STATUS_FACTORY,
  TRANSFER_STATUS_CONFIRMED,
  TRANSFER_STATUS_CANCELLED,
} from '../common/RolesConstants';
import {
  updateTransferRecordStatus,
  updateTransferStatusReset,
} from '../../redux/actions/updateTransferStatusActions';
import ActionConfirmModal from './ActionConfirmModal';
import ActionFailureModal from './ActionFailureModal';
import { TR_ITEM_DUPLICATE_END } from '../../redux/types/transferRecordItemTypes';
import ViewCustomTooltip from './ViewCustomTooltip';

interface IBreadCrumbItem {
  typeId: string;
  type: string;
  value: string;
}

interface InitialCountFiltersInterface {
  draft: string;
  confirmed: string;
  cancelled: string;
  pending: string;
  flagged: string;
  [key: string]: any;
}
const initialCountFilters = {
  draft: '',
  confirmed: '',
  cancelled: '',
  pending: '',
  flagged: '',
};

const Dashboard: FC = () => {
  const [limit] = useState(10);
  const [filterActive, setFilterActive] =
    useState<InitialCountFiltersInterface>(initialCountFilters);
  const [selectedRows, setSelectedRows] = useState<TransferRecordType[]>([]);
  const [filteredRowData, setFitleredRowData] = useState<TransferRecordType[]>(
    []
  );
  const [seasonSelect, setSeasonSelect] = useState<
    [{ value: string; label: string }]
  >([{ value: 'season', label: 'Season' }]);
  const [ppgSelect, setPPGSelect] = useState([{ value: 'ppg', label: 'PPG' }]);
  const [fromFactorySelect, setFromFactorySelect] = useState([
    { value: 'fromFactory', label: 'From Factory' },
  ]);
  const [toFactorySelect, setToFactorySelect] = useState([
    { value: 'toFactory', label: 'To Factory' },
  ]);
  const [fromFactoryGroupSelect, setFromFactoryGroupSelect] = useState([
    { value: 'fromFactoryGroup', label: 'From Factory Group' },
  ]);
  const [toFactoryGroupSelect, setToFactoryGroupSelect] = useState([
    { value: 'toFactoryGroup', label: 'To Factory Group' },
  ]);
  const [breadCrumbs, setBreadCrumbs] = useState<Array<IBreadCrumbItem>>([]);
  const [actionToConfirm, setActionToConfirm] =
    useState<ISelectionAction | null>(null);
  const [isActionConfirmModalOpen, setIsActionConfirmModalOpen] =
    useState<boolean>(false);
  const [isActionFailureModalOpen, setIsActionFailureModalOpen] =
    useState<boolean>(false);
  const [seasonDropdown, setSeasonDropdown] = useState<
    { value: string; label: string }[] | undefined
  >(undefined);
  const [ppgDropdownList, setPpgDropdownList] = useState<
    { value: string; label: string }[] | undefined
  >(undefined);
  const [fromGroupDropdownList, setFromGroupDropdownList] = useState<
    { value: string; label: string }[] | undefined
  >(undefined);
  const [fromDropdownList, setFromDropdownList] = useState<
    { value: string; label: string }[] | undefined
  >(undefined);
  const [toGroupDropdownList, setToGroupDropdownList] = useState<
    { value: string; label: string }[] | undefined
  >(undefined);
  const [toFactoryDropdownList, setToFactoryDropdownList] = useState<
    { value: string; label: string }[] | undefined
  >(undefined);
  const [cancelButtonDisabled, setCancelActionButtonDisabled] =
    useState<boolean>(true);
  const [confirmButtonDisabled, setConfirmActionButtonDisabled] =
    useState<boolean>(true);

  const [
    loading,
    totalRecords,
    userRole,
    updateTransferStatus,
    columnState,
    filterState,
    columnFilterLoading,
  ] = useAppSelector(state => [
    state.transferRecords.loading,
    state.transferRecords.totalRecords,
    state.userRole.role,
    state.updateTransferStatus,
    state.filter.column,
    state.filter.filter,
    state.filter.loading,
  ]);

  const pendingValues = userRole === roles.analyst ? ['Sourcing'] : ['Factory'];

  const [filterModel, setFilterModel] = useState<
    { [key: string]: any } | undefined
  >({
    none: 'none',
  });

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const clickCreateNew = () => {
    navigate('/new');
    dispatch({
      type: TR_ITEM_DUPLICATE_END,
      payload: {
        id: '',
        source: {
          attachments: [],
        },
      },
    });
  };

  const gridRef = useRef<AgGridReact<TransferRecordType>>(null);
  const countGridRef = useRef<AgGridReact<TransferRecordType>>(null);

  const filterCounts = useMemo(() => {
    let draft = 0;
    let confirmed = 0;
    let cancelled = 0;
    let pending = 0;
    let flagged = 0;

    filteredRowData.forEach(r => {
      if (r) {
        if (['Draft'].includes(r.status)) {
          draft += 1;
        } else if (['Confirmed'].includes(r.status)) {
          confirmed += 1;
        } else if (['Cancelled'].includes(r.status)) {
          cancelled += 1;
        } else if (
          userRole === 'WHQSourcing' &&
          ['Sourcing'].includes(r.status)
        ) {
          pending += 1;
        } else if (
          (userRole === 'FactoryPlanning' && ['Factory'].includes(r.status)) ||
          (userRole === 'PMOPlanning' && ['Factory'].includes(r.status)) ||
          (userRole === 'WHQSupplyPlanning' && ['Factory'].includes(r.status))
        ) {
          pending += 1;
        }
        if (r.importantFlag) {
          flagged += 1;
        }
      }
    });

    return {
      draft,
      confirmed,
      cancelled,
      pending,
      flagged,
    };
  }, [filteredRowData]);

  const columnDefs = useMemo<ColDef[]>(() => {
    return dashboardGridColumns(userRole);
  }, [userRole]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100,
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: true,
      suppressMenu: true,
      enablePivot: false,
      enableValue: false,
      enableRowGroup: false,
      tooltipComponent: ViewCustomTooltip,
    };
  }, []);

  const sideBar = useMemo<
    SideBarDef | string | string[] | boolean | null
  >(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressColumnFilter: false,
            suppressColumnSelectAll: false,
            suppressColumnExpandAll: false,
          },
          minWidth: 225,
          width: 225,
          maxWidth: 225,
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
      position: 'right',
      defaultToolPanel: '',
    };
  }, []);

  const debounceSaveGridColumnState = debounce((columnState: ColumnState[]) => {
    dispatch<any>(saveGridFilterColumnModel(filterState, columnState));
  }, 100);

  const onSaveGridColumnState = () => {
    if (!columnFilterLoading) {
      if (!gridRef.current?.api) return;
      const columnState = gridRef.current?.columnApi.getColumnState();

      debounceSaveGridColumnState(columnState);
    }
  };

  const onFirstDataRendered = () => {
    if (columnState) {
      gridRef.current?.columnApi.applyColumnState({
        state: columnState,
        applyOrder: true,
      });
    }

    if (filterState) {
      countGridRef.current?.api.setFilterModel({ ...filterState });
      gridRef.current?.api.setFilterModel({ ...filterState });
    }
  };

  useEffect(() => {
    if (columnState && filterState) {
      if (columnState.length === 0) {
        if (gridRef?.current?.columnApi) {
          gridRef.current?.columnApi.applyColumnState({
            state: columnState,
            applyOrder: true,
          });
        }
      }
      if (Object.keys(filterState).length === 0) {
        if (countGridRef.current?.api && gridRef.current?.api) {
          countGridRef.current?.api.setFilterModel({ ...filterState });
          gridRef.current?.api.setFilterModel({ ...filterState });
        }
      }
    }
  }, [filterState, columnState]);

  // Dropdown data
  const handleTopDropdownList: ReactSelectProps['onChange'] = (
    newValue: any,
    actionMeta: any
  ) => {
    const inputName = actionMeta.name;
    const inputValue = newValue.value;

    if (inputName === 'season') {
      setSeasonSelect([{ value: inputValue, label: inputValue }]);
      const seasonTopFilter: any = {
        seasonYearCode: { filterType: 'set', values: [inputValue] },
      };
      gridRef.current?.api.setFilterModel({
        ...filterModel,
        ...seasonTopFilter,
      });
    }
    if (inputName === 'ppg') {
      setPPGSelect([{ value: inputValue, label: inputValue }]);
      const ppgTopFilter: any = {
        productPlanningGroup: { filterType: 'set', values: [inputValue] },
      };
      gridRef.current?.api.setFilterModel({
        ...filterModel,
        ...ppgTopFilter,
      });
    }
    if (inputName === 'fFactory') {
      setFromFactorySelect([{ value: inputValue, label: inputValue }]);
      const fFactoryTopFilter: any = {
        fromFactoryCode: { filterType: 'set', values: [inputValue] },
      };
      gridRef.current?.api.setFilterModel({
        ...filterModel,
        ...fFactoryTopFilter,
      });
    }
    if (inputName === 'tFactory') {
      setToFactorySelect([{ value: inputValue, label: inputValue }]);
      const tFactoryTopFilter: any = {
        toFactoryCode: { filterType: 'set', values: [inputValue] },
      };
      gridRef.current?.api.setFilterModel({
        ...filterModel,
        ...tFactoryTopFilter,
      });
    }
    if (inputName === 'fFactoryGroup') {
      setFromFactoryGroupSelect([{ value: inputValue, label: inputValue }]);
      const fFactoryGroupTopFilter: any = {
        fromFactoryGroupCode: { filterType: 'set', values: [inputValue] },
      };
      gridRef.current?.api.setFilterModel({
        ...filterModel,
        ...fFactoryGroupTopFilter,
      });
    }
    if (inputName === 'tFactoryGroup') {
      setToFactoryGroupSelect([{ value: inputValue, label: inputValue }]);
      const tFactoryGroupTopFilter: any = {
        toFactoryGroupCode: { filterType: 'set', values: [inputValue] },
      };
      gridRef.current?.api.setFilterModel({
        ...filterModel,
        ...tFactoryGroupTopFilter,
      });
    }
    gridRef.current?.api.onFilterChanged();
  };

  useEffect(() => {
    const seasonRows: string[] = [];
    gridRef.current?.api?.forEachNode(node => {
      if (node.data !== undefined && node.data.seasonYearCode !== null) {
        if (seasonRows.indexOf(node.data.seasonYearCode) === -1) {
          seasonRows.push(node.data.seasonYearCode);
        }
      }
    });

    seasonRows.sort(sortByQuarterYear);

    const seasonFilterModel =
      gridRef.current?.api?.getFilterModel().seasonYearCode?.values || [];

    const seasonObjs: { value: string; label: string }[] = seasonRows
      .map(item => ({ value: item, label: item }))
      .filter(v => {
        return v.value !== null && v.value !== '';
      })
      // filters the top dropdown list based on the filter model from the grid
      .filter(item => {
        if (seasonFilterModel.length !== 0) {
          return seasonFilterModel.includes(item.value);
        }
        return true;
      });

    setSeasonDropdown(seasonObjs);
  }, [loading, filterCounts, seasonSelect]);

  useEffect(() => {
    const ppgRows: string[] = [];
    gridRef.current?.api?.forEachNode(node => {
      if (node.data !== undefined) {
        if (ppgRows.indexOf(node.data.productPlanningGroup) === -1) {
          ppgRows.push(node.data.productPlanningGroup);
        }
      }
    });

    const ppgFilterModel =
      gridRef.current?.api?.getFilterModel().productPlanningGroup?.values || [];

    const ppgObjs: { value: string; label: string }[] = ppgRows
      .map(item => ({
        value: item,
        label: item,
      }))
      .filter(v => {
        return v.value !== null && v.value !== '';
      })
      // filters the top dropdown list based on the filter model from the grid
      .filter(item => {
        if (ppgFilterModel.length !== 0) {
          return ppgFilterModel.includes(item.value);
        }
        return true;
      })
      .sort((a, b) => a.value.localeCompare(b.value));

    setPpgDropdownList(ppgObjs);
  }, [loading, filterCounts, ppgSelect]);

  useEffect(() => {
    const fromGroupRows: any[] = [];
    gridRef.current?.api?.forEachNode(node => {
      if (node.data !== undefined) {
        if (fromGroupRows.indexOf(node.data?.fromFactoryGroupCode) === -1) {
          fromGroupRows.push(node.data?.fromFactoryGroupCode);
        }
      }
    });

    const fromGroupFilterModel =
      gridRef.current?.api?.getFilterModel().fromFactoryGroupCode?.values || [];

    const fromGroupObjs: { value: string; label: string }[] = fromGroupRows
      .map(item => ({
        value: item,
        label: item,
      }))
      .filter(v => {
        return v.value !== null && v.value !== '';
      })
      // filters the top dropdown list based on the filter model from the grid
      .filter(item => {
        if (fromGroupFilterModel.length !== 0) {
          return fromGroupFilterModel.includes(item.value);
        }
        return true;
      })
      .sort((a, b) => a.value.localeCompare(b.value));

    setFromGroupDropdownList(fromGroupObjs);
  }, [loading, filterCounts, fromFactoryGroupSelect]);

  useEffect(() => {
    const fromFactoryRows: any[] = [];
    gridRef.current?.api?.forEachNode(node => {
      if (node.data !== undefined) {
        if (fromFactoryRows.indexOf(node.data?.fromFactoryCode) === -1) {
          fromFactoryRows.push(node.data?.fromFactoryCode);
        }
      }
    });

    const fromFactoryFilterModel =
      gridRef.current?.api?.getFilterModel().fromFactoryCode?.values || [];

    const fromFactoryObjs: { value: string; label: string }[] = fromFactoryRows
      .map(item => ({
        value: item,
        label: item,
      }))
      .filter(v => {
        return v.value !== null && v.value !== '';
      })
      // filters the top dropdown list based on the filter model from the grid
      .filter(item => {
        if (fromFactoryFilterModel.length !== 0) {
          return fromFactoryFilterModel.includes(item.value);
        }
        return true;
      })
      .sort((a, b) => a.value.localeCompare(b.value));

    setFromDropdownList(fromFactoryObjs);
  }, [loading, filterCounts, fromFactorySelect]);

  useEffect(() => {
    const toGroupRows: any[] = [];
    gridRef.current?.api?.forEachNode(node => {
      if (node.data !== undefined) {
        if (toGroupRows.indexOf(node.data.toFactoryGroupCode) === -1) {
          toGroupRows.push(node.data.toFactoryGroupCode);
        }
      }
    });

    const toGroupFilterModel =
      gridRef.current?.api?.getFilterModel().toFactoryGroupCode?.values || [];

    const toGroupObjs: { value: string; label: string }[] = toGroupRows
      .map(item => ({
        value: item,
        label: item,
      }))
      .filter(v => {
        return v.value !== null && v.value !== '';
      })
      // filters the top dropdown list based on the filter model from the grid
      .filter(item => {
        if (toGroupFilterModel.length !== 0) {
          return toGroupFilterModel.includes(item.value);
        }
        return true;
      })
      .sort((a, b) => a.value.localeCompare(b.value));

    setToGroupDropdownList(toGroupObjs);
  }, [loading, filterCounts, toFactoryGroupSelect]);

  useEffect(() => {
    const toFactoryRows: any[] = [];
    gridRef.current?.api?.forEachNode(node => {
      if (node.data !== undefined) {
        if (toFactoryRows.indexOf(node.data?.toFactoryCode) === -1) {
          toFactoryRows.push(node.data?.toFactoryCode);
        }
      }
    });

    const toFactoryFilterModel =
      gridRef.current?.api?.getFilterModel().toFactoryCode?.values || [];

    const toFactoryObjs: { value: string; label: string }[] = toFactoryRows
      .map(item => ({
        value: item,
        label: item,
      }))
      .filter(v => {
        return v.value !== null && v.value !== '';
      })
      // filters the top dropdown list based on the filter model from the grid
      .filter(item => {
        if (toFactoryFilterModel.length !== 0) {
          return toFactoryFilterModel.includes(item.value);
        }
        return true;
      })
      .sort((a, b) => a.value.localeCompare(b.value));

    setToFactoryDropdownList(toFactoryObjs);
  }, [loading, filterCounts, toFactorySelect]);

  useEffect(() => {
    dispatch<any>(fetchUserRole());
  }, []);

  // populates table with data
  useEffect(() => {
    // we are looking to see if the page as less than 10 records, if it doesn't we try to load more.
    if (totalRecords.length < 10) {
      dispatch<any>(fetchTransferRecords());
    }
  }, [totalRecords]);

  useEffect(() => {
    if (!updateTransferStatus.loading) {
      if (updateTransferStatus.success) {
        dispatch<any>(updateTransferStatusReset());
      } else if (updateTransferStatus.error) {
        setIsActionFailureModalOpen(true);
      }
    }
  }, [updateTransferStatus]);

  useEffect(() => {
    setFitleredRowData(totalRecords);
  }, [totalRecords]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    params.api.getToolPanelInstance('filters') as any as IFiltersToolPanel;
  }, []);

  const onFilterChanged = () => {
    const newFilterModel = {
      ...(gridRef.current?.api.getFilterModel() || {}),
    };

    setFilterModel({ ...newFilterModel });

    // I don't know why were delet this from the model. Add this back if something bad happens.
    // delete newFilterModel.status;
    countGridRef.current?.api.setFilterModel({ ...newFilterModel });
    dispatch<any>(saveGridFilterColumnModel(newFilterModel, columnState));
  };

  const fixName = (nameValue: string) => {
    const titleDic: { [id: string]: string } = {
      importantFlag: 'Flag',
      status: 'Status',
      createdBy: 'Created By',
      createdTimestamp: 'Create Date',
      dueDate: 'Due Date',
      seasonYearCode: 'Season',
      productPlanningGroup: 'PPG',
      styleNumber: 'Style Number',
      productCode: 'Product Code',
      modelName: 'Model Name',
      outsoleCode: 'Outsole Code',
      fromFactoryCode: 'From Factory',
      toFactoryCode: 'To Factory',
      fromFactoryGroupCode: 'From Factory Group',
      toFactoryGroupCode: 'To Factory Group',
      transferReason: 'Transfer Reason',
      transferType: 'Transfer Type',
      modifiedTimestamp: 'Modified Time',
      styleName: 'Style Name',
      confirmedDate: 'Confirmed Date',
      cancelledDate: 'Cancelled Date',
      colorwayCode: 'Clr',
      productCreationCenterCode: 'PCC',
      engineeringType: 'Engineering Type',
      tdCode: 'Td Code',
      attachments: 'Attachments',
      ToFactoryComments: 'To Factory Comments',
      FromFactoryComments: 'From Factory Comments',
      WHQComments: 'WHQ Comments',
      PMOComments: 'PMO Comments',
      modifiedBy: 'Modified By',
    };
    return titleDic[nameValue];
  };

  const trimDate = (dateValue: Date) => {
    return dateValue ? new Date(dateValue).toLocaleDateString() : '';
  };

  const resetSingleTopFilter = (
    breadCrumbId: string,
    breadCrumbValue: string
  ) => {
    if (
      breadCrumbId === 'seasonYearCode' &&
      seasonSelect.find(o => o.label === breadCrumbValue)
    ) {
      setSeasonSelect([{ value: 'season', label: 'Season' }]);
    }
    if (
      breadCrumbId === 'fromFactoryCode' &&
      fromFactorySelect.find(o => o.label === breadCrumbValue)
    ) {
      setFromFactorySelect([{ value: 'fromFactory', label: 'From Factory' }]);
    }
    if (
      breadCrumbId === 'productPlanningGroup' &&
      ppgSelect.find(o => o.label === breadCrumbValue)
    ) {
      setPPGSelect([{ value: 'ppg', label: 'PPG' }]);
    }
    if (
      breadCrumbId === 'toFactoryCode' &&
      toFactorySelect.find(o => o.label === breadCrumbValue)
    ) {
      setToFactorySelect([{ value: 'toFactory', label: 'To Factory' }]);
    }
    if (
      breadCrumbId === 'toFactoryGroupCode' &&
      toFactoryGroupSelect.find(o => o.label === breadCrumbValue)
    ) {
      setToFactoryGroupSelect([
        { value: 'toFactoryGroup', label: 'To Factory Group' },
      ]);
    }
    if (
      breadCrumbId === 'fromFactoryGroupCode' &&
      fromFactoryGroupSelect.find(o => o.label === breadCrumbValue)
    ) {
      setFromFactoryGroupSelect([
        { value: 'fromFactoryGroup', label: 'From Factory Group' },
      ]);
    }
  };

  const removeBreadCrumb = (breadCrumbItem: IBreadCrumbItem) => {
    let filteredCrumbItems = [];
    let breadCrumbArray = [];

    // eslint-disable-next-line no-prototype-builtins
    if (filterModel?.hasOwnProperty(breadCrumbItem.typeId)) {
      const activeModel = filterModel[breadCrumbItem.typeId];
      if (
        (filterModel[breadCrumbItem.typeId] !== undefined &&
          filterModel[breadCrumbItem.typeId].filterType === 'text') ||
        filterModel[breadCrumbItem.typeId].filterType === 'date'
      ) {
        delete filterModel[breadCrumbItem.typeId];

        breadCrumbArray = breadCrumbs.filter(
          (item: IBreadCrumbItem) => item !== breadCrumbItem
        );
        setBreadCrumbs(breadCrumbArray);
      }
      if (
        filterModel[breadCrumbItem.typeId] !== undefined &&
        filterModel[breadCrumbItem.typeId].filterType === 'set'
      ) {
        filteredCrumbItems = activeModel.values.filter(
          (item: string) => item !== breadCrumbItem.value
        );
        filterModel[breadCrumbItem.typeId].values = filteredCrumbItems;
        breadCrumbArray = breadCrumbs.filter(
          (item: IBreadCrumbItem) => item.value !== breadCrumbItem.value
        );
        setBreadCrumbs(breadCrumbArray);

        if (activeModel.values.length === 0) {
          delete filterModel[breadCrumbItem.typeId];
        }
      }
      gridRef.current?.api.setFilterModel(filterModel);
      resetSingleTopFilter(breadCrumbItem.typeId, breadCrumbItem.value);
    }

    /** CHECK MODEL TO DE-SELECT THE ACTIVE FILTER REMOVED FROM CRUMB CROSS  */
    if (filterModel) {
      const StatusModelValues = filterModel?.status?.values;
      const flagModelValues = filterModel?.importantFlag?.values;

      /** DE-SELECT THE CANCEL FILTER BUTTON, IF IT IS NOT IN MODEL */
      if (!StatusModelValues?.includes('Cancelled')) {
        filterActive.cancelled = '';
      }
      /** DE-SELECT THE Confirmed FILTER BUTTON, IF IT IS NOT IN MODEL */
      if (!StatusModelValues?.includes('Confirmed')) {
        filterActive.confirmed = '';
      }
      if (!StatusModelValues?.includes('Draft')) {
        filterActive.draft = '';
      }
      if (!flagModelValues?.includes('true')) {
        filterActive.flagged = '';
      }

      /** ANY ONE PENDING FILTER IS PRESENT IN MODEL */
      const anyPendingFilter = pendingValues.find(x =>
        StatusModelValues?.includes(x)
      );
      /** REMOVE PENDING FILTER IF NOT A ONE PENDING FILTER PRESENT */
      if (!anyPendingFilter) {
        filterActive.pending = '';
      }
    }
    /** SET ACTIVE FILTERS BY ABOVE CONDITION */
    setFilterActive(filterActive);
    gridRef.current?.api.onFilterChanged();
  };

  const makeBreadCrumbButton = (filterModelEntries: Array<Array<any>>) => {
    const breadCrumbArray = [];
    if (filterModelEntries.length > 0) {
      for (let i = 0; i < filterModelEntries.length; i += 1) {
        const element = filterModelEntries[i];
        for (let j = 0; j < element.length; j += 1) {
          const breadCrumbObjectDateText: {
            typeId: string;
            type: string;
            value: string;
          } = {
            typeId: '',
            type: '',
            value: '',
          };
          const item = element[j];
          if (item.filterType === 'date') {
            // eslint-disable-next-line prefer-destructuring
            breadCrumbObjectDateText.typeId = element[0];
            breadCrumbObjectDateText.type = fixName(element[0]);
            if (item.operator === 'AND') {
              const itemCondition1 = trimDate(item.condition1.dateFrom);
              const itemCondition2 = trimDate(item.condition2.dateFrom);
              const displayText = `${itemCondition1} AND ${itemCondition2}`;
              breadCrumbObjectDateText.value = displayText;
            } else if (item.operator === 'OR') {
              const itemCondition1 = trimDate(item.condition1.dateFrom);
              const itemCondition2 = trimDate(item.condition2.dateFrom);
              const displayText = `${itemCondition1} OR ${itemCondition2}`;
              breadCrumbObjectDateText.value = displayText;
            } else {
              breadCrumbObjectDateText.value = trimDate(item.dateFrom);
            }
            breadCrumbArray.push(breadCrumbObjectDateText);
          }
          if (item.filterType === 'set') {
            const { values } = item;
            for (let k = 0; k < values.length; k += 1) {
              const breadCrumbObject: {
                typeId: string;
                type: string;
                value: string;
              } = {
                typeId: '',
                type: '',
                value: '',
              };
              const ele = values[k];
              // eslint-disable-next-line prefer-destructuring
              breadCrumbObject.typeId = element[0];
              breadCrumbObject.type = fixName(element[0]);
              breadCrumbObject.value = ele;
              breadCrumbArray.push(breadCrumbObject);
            }
          }
          if (item.filterType === 'text') {
            // eslint-disable-next-line prefer-destructuring
            breadCrumbObjectDateText.typeId = element[0];
            breadCrumbObjectDateText.type = fixName(element[0]);
            breadCrumbObjectDateText.value = item.filter;
            breadCrumbArray.push(breadCrumbObjectDateText);
          }
        }
        setBreadCrumbs(breadCrumbArray);
      }
    } else {
      setBreadCrumbs([]);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const entry = Object.entries(filterModel!);
    makeBreadCrumbButton(entry);

    if (
      countGridRef.current &&
      countGridRef.current.api &&
      countGridRef.current.api.getModel
    ) {
      const modal = {
        rowsToDisplay: [],
        ...countGridRef.current.api.getModel(),
      };

      setFitleredRowData(modal.rowsToDisplay.map((r: RowNode) => r.data));
    }
  }, [filterModel]);

  const onFilterTextBoxChanged = () => {
    gridRef.current?.api.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
    countGridRef.current?.api.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  };

  /** HANDLE ALL COUNT FILTERS  */
  const applyMultipleCountFilters = (filterName: string) => {
    const applyFilters = (newActiveFilter: InitialCountFiltersInterface) => {
      /** GET ALREADY APPLIED OLD FILTERS MODEL */
      const oldFilter = gridRef.current?.api.getFilterModel();

      /** PREPARE NEW FILTER MODEL TO APPLY */
      const prepareNewFilter: any = {};
      /** VALUES FOR STATUS FILTERS */
      const value: any[] = [];

      /** HANDLE FLAGGED FILTER  */
      if (newActiveFilter.flagged === 'flagged') {
        prepareNewFilter.importantFlag = {
          filterType: 'set',
          values: ['true'],
        };
      } else {
        /** REMOVE ON AGAIN CLICK */
        /** DE-SELECTION */
        delete prepareNewFilter.importantFlag;
        delete oldFilter?.importantFlag;
      }

      /** HANDLE PENDING FILTER  */
      if (newActiveFilter.pending) {
        const oldFilterValues = oldFilter?.status?.values;
        const appliedPendingFilter = pendingValues.find(x =>
          oldFilterValues?.includes(x)
        );
        /** APPLY ALL THREE PENDING FILTER */
        if (!appliedPendingFilter) {
          pendingValues.map(x => value.push(x));
        } else {
          /** IF ANY PENDING FILTER IS SELECTED EARLIER THEN ONLY APPLY THOSE */
          oldFilterValues?.map(
            (x: string) => pendingValues.includes(x) && value.push(x)
          );
        }
        prepareNewFilter.status = { filterType: 'set', values: value };
      } else {
        /** REMOVE PENDING FILTERS FROM SELECTED FILTERS */
        /** DE-SELECTION */
        const filteredValues = oldFilter?.status?.values?.filter(
          (x: string) => !pendingValues.includes(x)
        );
        prepareNewFilter.status = { filterType: 'set', values: filteredValues };
      }

      /** HANDLE DRAFT FILTER */
      if (newActiveFilter.draft) {
        value.push(newActiveFilter.draft);
      }

      /** HANDLE CONFIRM FILTER */
      if (newActiveFilter.confirmed) {
        value.push(newActiveFilter.confirmed);
      }

      /** HANDLE CANCEL FILTER */
      if (newActiveFilter.cancelled) {
        value.push(newActiveFilter.cancelled);
      }

      /** NEW FILTER FOR STATUS */
      prepareNewFilter.status = { filterType: 'set', values: value };

      /** OVER WRITE THE OLD VALUES FOR COUNT FILTERS */
      const filterToSet = {
        ...oldFilter,
        ...prepareNewFilter,
      };

      /** IF NO STATUS FILTER VALUES PRESENT  */
      if (filterToSet?.status?.values?.length === 0) {
        delete filterToSet.status;
      }

      /** APPLY THE NEW FILTER MODEL */
      gridRef.current?.api.setFilterModel(filterToSet);
    };

    /** To make active only one below filter at a time */
    /** flagged is excluded from below array */
    const statusFilterArray = ['draft', 'cancelled', 'confirmed', 'pending'];
    const newFilter: any = {};
    if (statusFilterArray.includes(filterName)) {
      // eslint-disable-next-line no-return-assign
      statusFilterArray.map(key =>
        key === filterName
          ? (newFilter[key] = filterName)
          : (newFilter[key] = '')
      );
    }
    /** SET STATE OF ACTIVE FILTER  */
    setFilterActive(state => {
      const newActiveFilter = {
        ...state,
        ...newFilter,
        [filterName]: state[filterName] ? '' : filterName,
      };

      /** PREPARE AND APPLY NEW FILTERS */
      applyFilters(newActiveFilter);
      return newActiveFilter;
    });
  };

  const clearAllFilters = () => {
    setSeasonSelect([{ value: 'season', label: 'Season' }]);
    setToFactorySelect([{ value: 'toFactory', label: 'To Factory' }]);
    setPPGSelect([{ value: 'ppg', label: 'PPG' }]);
    setFromFactorySelect([{ value: 'fromFactory', label: 'From Factory' }]);
    setToFactoryGroupSelect([
      { value: 'toFactoryGroup', label: 'To Factory Group' },
    ]);
    setFromFactoryGroupSelect([
      { value: 'fromFactoryGroup', label: 'From Factory Group' },
    ]);
    gridRef.current?.api.setQuickFilter(
      ((document.getElementById('filter-text-box') as HTMLInputElement).value =
        '')
    );
    countGridRef.current?.api.setQuickFilter(
      ((document.getElementById('filter-text-box') as HTMLInputElement).value =
        '')
    );
    setFilterActive(initialCountFilters);
    gridRef.current?.api.setFilterModel(null);
  };

  const downloadView = useCallback(() => {
    gridRef.current?.api.exportDataAsExcel();
  }, []);

  const onSelectionActionClick = (status: string) => {
    const selectRowList = gridRef.current?.api.getSelectedRows();

    const ids: string[] =
      selectRowList?.map(row => {
        const { _id: id } = row;

        return id;
      }) || [];
    const data = { ids, status };

    setActionToConfirm(data);
    setSelectedRows([]);
    setIsActionConfirmModalOpen(true);
  };

  const handleClearActionToConfirm = () => {
    setActionToConfirm(null);
    setIsActionConfirmModalOpen(false);
  };

  const handleActionFailureClose = () => {
    dispatch<any>(updateTransferStatusReset());
    setIsActionFailureModalOpen(false);
  };

  const handleConfirmSelectionAction = () => {
    if (actionToConfirm) {
      dispatch<any>(
        updateTransferRecordStatus(actionToConfirm.status, {
          ids: actionToConfirm.ids,
        })
      );
      handleClearActionToConfirm();
    }
  };

  const onSelectionChanged = () => {
    const selectedData = gridRef.current?.api.getSelectedRows();
    if (selectedData !== undefined) {
      setSelectedRows(selectedData);
    }
  };

  useEffect(() => {
    if (Array.isArray(selectedRows) && selectedRows.length > 0) {
      // eslint-disable-next-line array-callback-return, consistent-return
      const actionList = selectedRows.map(element => {
        if (
          element.status === TRANSFER_STATUS_DRAFT ||
          element.status === TRANSFER_STATUS_FACTORY ||
          element.status === TRANSFER_STATUS_CONFIRMED ||
          element.status === TRANSFER_STATUS_CANCELLED
        ) {
          return element.status;
        }
      });

      setCancelActionButtonDisabled(false);
      setConfirmActionButtonDisabled(false);

      if (actionList.includes(TRANSFER_STATUS_DRAFT)) {
        setCancelActionButtonDisabled(true);
      }
      if (actionList.includes(TRANSFER_STATUS_FACTORY)) {
        setConfirmActionButtonDisabled(true);
        setCancelActionButtonDisabled(true);
      }
      if (actionList.includes(TRANSFER_STATUS_CONFIRMED)) {
        setConfirmActionButtonDisabled(true);
      }
      if (actionList.includes(TRANSFER_STATUS_CANCELLED)) {
        setCancelActionButtonDisabled(true);
      }
    } else {
      setCancelActionButtonDisabled(true);
      setConfirmActionButtonDisabled(true);
    }
  }, [selectedRows]);

  return (
    <div className="page-container">
      {updateTransferStatus.loading ? (
        <div className="ScreenContainer Overlay flexContainer update-transfer-spinner-container">
          <div className="flexItemSpinner">
            <Spinner size="large" />
          </div>
        </div>
      ) : null}
      <ActionConfirmModal
        onConfirm={handleConfirmSelectionAction}
        isOpen={isActionConfirmModalOpen}
        onDismiss={handleClearActionToConfirm}
        actionToConfirm={actionToConfirm}
      />
      <ActionFailureModal
        isOpen={isActionFailureModalOpen}
        onDismiss={handleActionFailureClose}
        actionData={updateTransferStatus.data}
      />
      <div className="dashboard-container">
        <div>
          <div className="eds-grid eds-grid--m-cols-7 filter-header-container">
            <Select
              id="season-select"
              options={seasonDropdown}
              value={seasonSelect}
              label="Season"
              onChange={handleTopDropdownList}
              name="season"
              className="filterSelect"
            />
            <Select
              id="ppg-select"
              options={ppgDropdownList}
              value={ppgSelect}
              label="PPG"
              onChange={handleTopDropdownList}
              name="ppg"
              className="filterSelect"
            />
            <Select
              id="from-factory-group-select"
              options={fromGroupDropdownList}
              value={fromFactoryGroupSelect}
              label="From Factory Group"
              onChange={handleTopDropdownList}
              name="fFactoryGroup"
              className="filterSelect"
            />
            <Select
              id="from-factory-select"
              options={fromDropdownList}
              value={fromFactorySelect}
              label="From Factory"
              onChange={handleTopDropdownList}
              name="fFactory"
              className="filterSelect"
            />
            <Select
              id="to-factory-group-select"
              options={toGroupDropdownList}
              value={toFactoryGroupSelect}
              label="To Factory Group"
              onChange={handleTopDropdownList}
              name="tFactoryGroup"
              className="filterSelect"
            />
            <Select
              id="to-factory-select"
              options={toFactoryDropdownList}
              value={toFactorySelect}
              label="To Factory"
              onChange={handleTopDropdownList}
              name="tFactory"
              className="filterSelect"
            />
            <div>
              <Button
                onClick={clearAllFilters}
                className="clear-filter-button"
                size="small"
                variant="ghost"
              >
                Clear All
              </Button>
            </div>
          </div>
        </div>
        <div className="eds-spacing--px-56">
          <div className="search-button-group">
            <div className="statusFilterGroup">
              <div
                className="statusStyle"
                onClick={() => applyMultipleCountFilters('draft')}
                aria-hidden="true"
              >
                <Button
                  className="preset-buttons"
                  size="small"
                  variant="secondary"
                  title="Status = 'Draft'"
                >
                  Draft
                </Button>
                <Button
                  disableRipple
                  size="small"
                  className="filter-count-btn"
                  variant={
                    filterActive.draft !== 'draft' ? 'secondary' : 'primary'
                  }
                >
                  {filterCounts.draft}
                </Button>
              </div>
              <div
                className="statusStyle"
                onClick={() => applyMultipleCountFilters('pending')}
                aria-hidden="true"
              >
                <Button
                  className="preset-buttons"
                  size="small"
                  variant="secondary"
                  title={
                    userRole === roles.analyst
                      ? // eslint-disable-next-line quotes
                        "Status = 'Sourcing'"
                      : // eslint-disable-next-line quotes
                        "Status = 'Factory'"
                  }
                >
                  Pending
                </Button>
                <Button
                  disableRipple
                  size="small"
                  className="filter-count-btn"
                  variant={
                    filterActive.pending !== 'pending' ? 'secondary' : 'primary'
                  }
                >
                  {filterCounts.pending}
                </Button>
              </div>
              <div
                className="statusStyle"
                onClick={() => applyMultipleCountFilters('confirmed')}
                aria-hidden="true"
              >
                <Button
                  className="preset-buttons"
                  size="small"
                  variant="secondary"
                  title="Status = 'Confirmed'"
                >
                  Confirmed
                </Button>
                <Button
                  disableRipple
                  size="small"
                  className="filter-count-btn"
                  variant={
                    filterActive.confirmed !== 'confirmed'
                      ? 'secondary'
                      : 'primary'
                  }
                >
                  {filterCounts.confirmed}
                </Button>
              </div>
              <div
                className="statusStyle"
                onClick={() => applyMultipleCountFilters('cancelled')}
                aria-hidden="true"
              >
                <Button
                  className="preset-buttons"
                  size="small"
                  variant="secondary"
                  title="Status = 'Cancelled'"
                >
                  Cancelled
                </Button>
                <Button
                  disableRipple
                  size="small"
                  className="filter-count-btn"
                  variant={
                    filterActive.cancelled !== 'cancelled'
                      ? 'secondary'
                      : 'primary'
                  }
                >
                  {filterCounts.cancelled}
                </Button>
              </div>
              <div
                className="statusStyle"
                onClick={() => applyMultipleCountFilters('flagged')}
                aria-hidden="true"
              >
                <Button
                  className="preset-buttons"
                  size="small"
                  variant="secondary"
                  title="Status = 'Flagged'"
                >
                  Flagged
                </Button>
                <Button
                  disableRipple
                  size="small"
                  className="filter-count-btn"
                  variant={
                    filterActive.flagged !== 'flagged' ? 'secondary' : 'primary'
                  }
                >
                  {filterCounts.flagged}
                </Button>
              </div>
            </div>
            <div className="action-button-group">
              <TextField
                label=""
                type="text"
                id="filter-text-box"
                placeholder="Filter by keyword"
                onInput={onFilterTextBoxChanged}
                className="keyword-filter"
              />
              <Button
                size="medium"
                variant="secondary"
                className="action-button"
                onClick={downloadView}
                disabled={totalRecords.length <= 0}
              >
                Download
              </Button>
              {userRole !== roles.planning && (
                <Button onClick={clickCreateNew} className="action-button">
                  Create New
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="eds-spacing--px-56">
          <div className="eds-flex eds-gap--8 eds-flex--direction-row eds-flex--align-items-center eds-flex--align-content-flex-start breadcrumb-button-group">
            <div className="button-group eds-flex eds-flex--nowrap">
              Current Filters:{' '}
            </div>
            <div className="breadcrumb-buttons eds-flex--grow-1">
              {breadCrumbs
                .slice(0, limit || breadCrumbs.length)
                .map((item, idx) => {
                  let val = item.value;

                  switch (val) {
                    case 'true':
                      val = 'Yes';
                      break;
                    case 'false':
                      val = 'No';
                      break;
                    default:
                      break;
                  }

                  return (
                    <Button
                      // eslint-disable-next-line react/no-array-index-key
                      key={idx}
                      size="small"
                      variant="secondary"
                      onClick={() => {
                        removeBreadCrumb(item);
                      }}
                      afterSlot={<Close className="the-x" />}
                      className="x-small"
                    >
                      {/* IF ELSE CONDITION TO HANDLE FLAG TRUE VALUE */}
                      {item.type} = {val}
                    </Button>
                  );
                })}{' '}
            </div>
            {/* {breadCrumbs.length > 5 ? '...' : ''} */}
            {userRole === roles.analyst ? (
              <div className="action-buttons-wrapper eds-flex eds-flex--nowrap">
                <Button
                  size="small"
                  id="dashboard-cancel-button"
                  variant="secondary"
                  onClick={() =>
                    onSelectionActionClick(API_TRANSFER_STATUS_CANCEL)
                  }
                  disabled={
                    cancelButtonDisabled || selectedRows.length > 20 || loading
                  }
                >
                  Cancel Transfer
                </Button>
                <Button
                  size="small"
                  id="dashboard-confirm-button"
                  onClick={() =>
                    onSelectionActionClick(API_TRANSFER_STATUS_CONFIRM)
                  }
                  disabled={
                    confirmButtonDisabled || selectedRows.length > 20 || loading
                  }
                >
                  Confirm
                </Button>
              </div>
            ) : null}
          </div>
        </div>
        <div className="eds-spacing--px-56" style={{ height: '100%' }}>
          <div
            style={{ height: 'calc( 100% - 20px)', width: '100%' }}
            className="ag-theme-alpine"
          >
            <AgGridReact<TransferRecordType>
              ref={gridRef}
              rowData={totalRecords}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              pagination
              sideBar={sideBar}
              onFilterChanged={onFilterChanged}
              rowSelection="multiple"
              headerHeight={60}
              rowHeight={40}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              onSelectionChanged={onSelectionChanged}
              suppressCopyRowsToClipboard
              onFirstDataRendered={onFirstDataRendered}
              onSortChanged={onSaveGridColumnState}
              onColumnVisible={onSaveGridColumnState}
              onColumnPinned={onSaveGridColumnState}
              onColumnResized={onSaveGridColumnState}
              onColumnMoved={onSaveGridColumnState}
              onColumnRowGroupChanged={onSaveGridColumnState}
              onColumnValueChanged={onSaveGridColumnState}
              onColumnPivotChanged={onSaveGridColumnState}
            />
            {/* keeping below hidden grid to get the proper filtered list without status filter to show the buttons with count on top */}
            <AgGridReact<TransferRecordType>
              ref={countGridRef}
              rowData={totalRecords}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              className="data-grid-without-status-filter"
            />
          </div>
          {loading && (
            <div className="statusbar">
              <span className="statusbar-text">
                Loading transfer records...
              </span>
              <Spinner size="medium" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
