import { Select } from '@nike/eds';
import { FC } from 'react';
import { Props as ReactSelectProps } from 'react-select';
import makeAnimated from 'react-select/animated';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { roles } from '../../common/RolesConstants';
import {
  TRANSFER_TYPE_OUTSOLE_COMPLETE,
  TRANSFER_TYPE_TOOLING,
  ITransferData,
} from '../NewTranferInterface';
import { validateOnChange } from '../Validation';
import {
  errorActionCreator,
  updateHasBlockingError,
} from '../../../redux/actions/errorActions';
import { updateInputTouched } from '../../../redux/actions/inputActions';

interface FieldProps {
  transferData: any | ITransferData;
  setTransferData: (params: any) => any;
}

const ColorwayCode: FC<FieldProps> = ({ transferData, setTransferData }) => {
  const animatedComponents = makeAnimated();
  const [
    transferItemData,
    userRole,
    isEditMode,
    productColorwayCodeList,
    error,
  ] = useAppSelector(state => [
    state.transferRecordItem.transferItemData.source,
    state.userRole.role,
    state.input.isEditMode,
    state.newTransfer.productColorwayCodeList,
    state.error,
  ]);

  const dispatch = useAppDispatch();

  const handleSelectInputChange: ReactSelectProps['onChange'] = (
    value: any,
    actionMeta: any
  ) => {
    const inputName: string = actionMeta?.name || '';
    const validationData = {
      productColorwayCodeList,
    };

    setTransferData({ ...transferData, productColorwayCode: value });

    dispatch<any>(updateHasBlockingError(false));

    const validation = validateOnChange(
      actionMeta,
      value,
      userRole,
      validationData
    );

    dispatch<any>(errorActionCreator(inputName, validation.message));

    if (validation.blockingError) {
      dispatch<any>(updateHasBlockingError(true));
    }

    dispatch<any>(updateInputTouched(true));
  };

  return (
    <Select
      closeMenuOnSelect={false}
      components={animatedComponents}
      isClearable
      isCreatable={
        userRole === roles.analyst &&
        (transferData?.productColorwayCode?.length === 0 ||
          transferData?.productColorwayCode === null)
      }
      options={productColorwayCodeList}
      id="product-col-code-select"
      label="Product Colorway Code"
      name="productColorwayCode"
      onChange={handleSelectInputChange}
      value={transferData.productColorwayCode}
      isDisabled={
        !transferData?.transferType?.value ||
        transferData?.transferType?.value === TRANSFER_TYPE_TOOLING ||
        transferData?.transferType?.value === TRANSFER_TYPE_OUTSOLE_COMPLETE ||
        !transferData?.styleNumber?.value ||
        ((userRole === roles.factory || userRole === roles.pmo) &&
          isEditMode &&
          isEditMode &&
          transferItemData.status !== 'Factory' &&
          transferItemData.status !== 'Draft')
      }
      isMulti={!error.productColorwayCodeError && !isEditMode}
      hasErrors={error.productColorwayCodeError}
      errorMessage={error.productColorwayCodeErrorMessage}
    />
  );
};

export default ColorwayCode;
